import { greekIt } from 'app/util/utils';
import { serializeBaselinePercentValue, deserializeBaselinePercentValue } from 'app/stores/alerting/policyUtils';
import { GREEK_METRICS } from 'app/util/constants';
import { ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY } from 'shared/alerting/constants';

// DESERIALIZE START
function deserializeThresholdProperties(threshold) {
  const { policyID, ...restThreshold } = threshold;

  return {
    ...restThreshold,
    policy_id: policyID
  };
}

function deserializeThresholdMitigations(threshold) {
  const { mitigations, ...restThreshold } = threshold;

  if (mitigations && mitigations.length > 0) {
    return {
      ...restThreshold,
      mitigations: mitigations.map((mitigation) => {
        const newMitigation = { ...mitigation };

        // mitigations aren't as luxuriously structured as activate options are (there's no time unit)
        // so we're a bit more crude with what goes on in here until we can get rid of V3
        if (mitigation.applyStrategy === 'userAckOrTimeout') {
          newMitigation.applyTimeout = parseInt(mitigation.applyTimeout.replace('s', ''), 10) / 60;

          if (!Number.isFinite(newMitigation.applyTimeout)) {
            newMitigation.applyTimeout = 15;
          }
        }

        if (mitigation.clearStrategy === 'userAckOrTimeout') {
          newMitigation.clearTimeout = parseInt(mitigation.clearTimeout.replace('s', ''), 10) / 60;

          // safety fallback to default value
          if (!Number.isFinite(newMitigation.clearTimeout)) {
            newMitigation.clearTimeout = 15;
          }
        }

        if (newMitigation.clearTimeout === null) {
          delete newMitigation.clearTimeout;
        }

        if (newMitigation.applyTimeout === null) {
          delete newMitigation.applyTimeout;
        }

        return newMitigation;
      })
    };
  }

  return threshold;
}

// Input values in any unit,
// Output values in minute format
function deserializeGracePeriod(inactivityTimeUntilClear, defaultValue = 20) {
  if (!inactivityTimeUntilClear) {
    return defaultValue;
  }

  let gracePeriodValue = Number(inactivityTimeUntilClear.replace('s', '').replace('m', '').replace('h', ''));

  if (inactivityTimeUntilClear.endsWith('s')) {
    gracePeriodValue /= 60;
  } else if (inactivityTimeUntilClear.endsWith('h')) {
    gracePeriodValue /= 3600;
  }

  return gracePeriodValue || defaultValue;
}

function deserializeThresholdActivate(threshold, policy = {}) {
  const { activationSettings: policyActivationSettings, rule } = policy;
  const { activationSettings, ...restThreshold } = threshold;
  let { window = '', matchThreshold, inactivityTimeUntilClear } = activationSettings || {};
  const ruleThreshold = (rule?.levels || []).find(
    (level) => ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY[level.severity] === restThreshold.severity
  );

  // Set from rule if available
  if (ruleThreshold?.rolling_window) {
    window = ruleThreshold?.rolling_window?.window_length;
    inactivityTimeUntilClear = ruleThreshold?.rolling_window?.grace_period;
    matchThreshold = ruleThreshold?.rolling_window?.trigger_count;
  }

  const isToggleModePolicy = policyActivationSettings?.mode === 'activationModeToggle';
  const match = window.match(/^(\d+)(h|m|s)$/);
  let timeWindow = (match && match[1]) || 0;
  let shortTimeUnit = (match && match[2]) || 'm';

  if (shortTimeUnit === 's') {
    timeWindow /= 60;

    if (timeWindow >= 60) {
      timeWindow /= 60;
      shortTimeUnit = 'h';
    }
  }

  const defaultActivate = {
    timeWindow: Number(timeWindow) === 0 ? 2 : Number(timeWindow),
    timeUnit: shortTimeUnit === 'h' ? 'hour' : 'minute',
    times: Number(matchThreshold) === 0 ? 5 : Number(matchThreshold),
    gracePeriod: deserializeGracePeriod(inactivityTimeUntilClear)
  };

  const toggleModeActivate = { timeUnit: 'minute', times: 1, timeWindow: 1, gracePeriod: 5 };

  return {
    ...restThreshold,
    activate: isToggleModePolicy ? toggleModeActivate : defaultActivate
  };
}

function deserializeThresholdConditions(threshold, policy) {
  // direction and fallback settings are in a baseline condition if found
  const baselineCondition = threshold.conditions.find((t) => t.type === 'baseline' || t.type === 'baselinePercent');
  const defaultCondition = threshold.conditions[0] || {};

  if (baselineCondition) {
    const { fallbackSettings } = baselineCondition;

    if (fallbackSettings) {
      threshold.fallbackSettings = fallbackSettings;
    }
  }

  // Coerce invalid 'thresholdDirectionNone' to default 'currentToHistory' for direction
  threshold.direction = defaultCondition.direction === 'historyToCurrent' ? 'historyToCurrent' : 'currentToHistory';

  threshold.keySettings = defaultCondition.keySettings || {};

  const conditions = threshold.conditions.map((condition) => {
    const transformedCondition = {
      operator: condition.operator || 'greaterThanOrEquals',
      comparisonValue: condition.comparisonValue,
      comparisonValueFactor: 1,
      type: condition.type
    };

    if (condition.type === 'ratio') {
      transformedCondition.operator = '';
      transformedCondition.ratioSettings = condition.ratioSettings || {};
      transformedCondition.ratioSettings.direction = transformedCondition.ratioSettings.direction || 'DirectionLeft';
      transformedCondition.ratioSettings.lhsMetricPosition = condition.ratioSettings.lhsMetricPosition || 0;
      transformedCondition.ratioSettings.rhsMetricPosition = condition.ratioSettings.rhsMetricPosition || 0;
    }

    if (condition.type === 'interfaceCapacity') {
      transformedCondition.comparisonValue = condition.comparisonValue / 10 ** 6;
      transformedCondition.comparisonValueFactor = 10 ** 6;
    }

    if (transformedCondition.type === 'static') {
      const metricIndex = policy.metrics.indexOf(condition.metric);

      if (GREEK_METRICS.includes(condition.metric)) {
        const { adjusted, magnitude } = greekIt(transformedCondition.comparisonValue);
        transformedCondition.comparisonValue = adjusted;
        transformedCondition.comparisonValueFactor = magnitude;
      }
      transformedCondition.metric = condition.metric;
      transformedCondition.value_select = `metric_${metricIndex}`;
    }

    if (transformedCondition.type.startsWith('baseline')) {
      transformedCondition.value_select = 'metric_0';
    }

    if (transformedCondition.type === 'baselinePercent') {
      /**
       * WARNING: Baseline Percent conditions are tricky, proceed carefully!
       *
       * In UI-APP:
       * - baselinePercent values are stored as % of whole, but input by users as a % increase or % decrease.
       * - For currentToHistory, a user input of "20% above baseline" is converted to 120% before being sent to the API.
       * - For historyToCurrent, a user input of "20% below baseline" is converted to 125% before being sent to the API.
       * - The difference is because a 20% decrease in X => Y corresponds to a 25% increase from Y => X. Then the value is converted to a percentage of whole.
       * - While UI allows users to specify % above/below the baseline, chf-activate actually swaps the baseline & current values when doing its comparison.
       * - This is a workaround for the lack of a less-than feature in chf-activate.
       *
       * In CHF-ACTIVATE:
       * - chf-activate compares two sets of traffic data for a given policy ID: current data, and baseline data.
       * - It iterates over each entry in the data sets to see if alarm needs to be triggered.
       * - If threshold is current_to_history, it checks if the current[i] is X% greater than baseline[i].
       * - Example: a current_to_history baselinePercent with a value of 120%: a baseline[i] of 100 and a current[i] of 121 will trigger an alarm.
       * - If threshold is history_to_current, it checks if baseline[i] is X% greater than current[i].
       * - Example: a history_to_current with a baselinePercent of 120%: a current[i] of 96 and a baseline[i] of 120 will trigger an alarm.
       *
       * In the FUTURE:
       * - chf-activate should take a simple positive or negative value and make comparisons accordingly.
       */
      transformedCondition.comparisonValue -= 100;
      if (threshold.direction === 'historyToCurrent') {
        transformedCondition.comparisonValue = deserializeBaselinePercentValue(transformedCondition.comparisonValue);
      }
    }

    // UpDown policies only use boolean operators
    // This handles legacy policy operators.
    if (
      policy.applicationMetadata?.type === 'state-change' &&
      !['equals', 'notEquals'].includes(transformedCondition.operator)
    ) {
      transformedCondition.operator = 'equals';
    }

    return transformedCondition;
  });

  return {
    ...threshold,
    conditions
  };
}

// DESERIALIZE END

// SERIALIZE START

const thresholdPropsToKeepOnSerialize = [
  'thresholdID',
  'policy_id',
  'severity',
  'conditions',
  'activate',
  'ackRequired',
  'description',
  'direction',
  'fallbackSettings',
  'filters',
  'mitigations',
  'keySettings',
  'notificationChannels'
];

function pruneThresholdProperties(threshold) {
  return Object.keys(threshold).reduce((acc, item) => {
    if (thresholdPropsToKeepOnSerialize.includes(item)) {
      acc[item] = threshold[item];
    }

    return acc;
  }, {});
}

function serializeThresholdProperties(threshold) {
  const { policy_id, policyID, thresholdID, ...restThreshold } = threshold;
  const transformedThreshold = {
    ...restThreshold,
    policyID: policyID || policy_id
  };

  if (thresholdID) {
    transformedThreshold.thresholdID = thresholdID;
  }

  return transformedThreshold;
}

function serializeThresholdActivate(threshold) {
  const { activate, ...restThreshold } = threshold;

  return {
    ...restThreshold,
    activationSettings: {
      matchThreshold: `${activate.times}`,
      window: `${activate.timeWindow}${activate.timeUnit === 'hour' ? 'h' : 'm'}`,
      inactivityTimeUntilClear: `${activate.gracePeriod}m`
    }
  };
}

function serializeThresholdConditions(threshold, policy) {
  const { direction, fallbackSettings, keySettings: thresholdKeySettings = {}, ...restThreshold } = threshold;
  const [primaryMetric] = policy.metrics;
  let keySettings = null;

  const currentKeysChanged =
    thresholdKeySettings?.nTopKeysEvaluated > 0 && thresholdKeySettings?.nTopKeysEvaluated < policy.nTopKeysEvaluated;
  const baselineKeysChanged =
    thresholdKeySettings?.nTopKeysStoredInBaseline > 0 &&
    thresholdKeySettings?.nTopKeysStoredInBaseline < policy.nTopKeysAddedToBaseline;

  // Use keySettings if nonzero & differ from policy settings
  if (currentKeysChanged || baselineKeysChanged) {
    keySettings = {
      nTopKeysEvaluated: thresholdKeySettings.nTopKeysEvaluated || policy.nTopKeysEvaluated,
      nTopKeysStoredInBaseline: thresholdKeySettings.nTopKeysStoredInBaseline || policy.nTopKeysAddedToBaseline
    };
  }

  const conditions = threshold.conditions.map((condition) => {
    const transformedCondition = {
      operator: condition.operator || 'greaterThanOrEquals',
      comparisonValue: condition.comparisonValue,
      type: condition.type,
      direction,
      keySettings
    };

    if (condition.type === 'keyNotInTop') {
      delete transformedCondition.operator;
      delete transformedCondition.comparisonValue;
    }

    if (condition.type === 'baseline') {
      transformedCondition.metric = primaryMetric;
    }

    // add threshold fallbackSettings to all baseline conditions
    if (condition.type.startsWith('baseline') && fallbackSettings) {
      transformedCondition.fallbackSettings = fallbackSettings;
    }

    if (condition.type === 'baselinePercent') {
      /**
       * WARNING: Baseline Percent conditions are tricky! See comment above before editing.
       */
      if (threshold.direction === 'historyToCurrent') {
        transformedCondition.comparisonValue = serializeBaselinePercentValue(transformedCondition.comparisonValue);
      }

      transformedCondition.comparisonValue += 100;
    }

    if (condition.type === 'ratio') {
      transformedCondition.ratioSettings = condition.ratioSettings || {};
      transformedCondition.ratioSettings.lhsMetricPosition = parseInt(
        transformedCondition.ratioSettings.lhsMetricPosition
      );
      transformedCondition.ratioSettings.rhsMetricPosition = parseInt(
        transformedCondition.ratioSettings.rhsMetricPosition
      );
      transformedCondition.ratioSettings.margin = parseFloat(transformedCondition.ratioSettings.margin);
    }

    if (transformedCondition.type === 'static') {
      const [, metricIndex] = condition.value_select.split('_');
      const metric = policy.metrics[parseInt(metricIndex)];
      transformedCondition.metric = metric || condition.metric;

      // Only multiply by comparisonValueFactor for greek metrics
      if (GREEK_METRICS.includes(transformedCondition.metric)) {
        transformedCondition.comparisonValue = `${
          transformedCondition.comparisonValue * condition.comparisonValueFactor
        }`;
      }
    }

    return transformedCondition;
  });

  return {
    ...restThreshold,
    conditions
  };
}

function serializeThresholdMitigations(threshold) {
  const { mitigations, ...restThreshold } = threshold;

  const newMitigations = mitigations.map((mitigation) => {
    if (mitigation.applyStrategy !== 'userAckOrTimeout') {
      delete mitigation.applyTimeout;
    } else {
      // add time unit, protobuff needs it
      mitigation.applyTimeout += 'm';
    }

    if (mitigation.clearStrategy !== 'userAckOrTimeout') {
      delete mitigation.clearTimeout;
    } else {
      // add time unit, protobuff needs it
      mitigation.clearTimeout += 'm';
    }

    return mitigation;
  });

  return {
    ...restThreshold,
    mitigations: newMitigations
  };
}

function deserialize(policy) {
  // careful here, a little misleading: the call chaining actually mutates the object and then returns it so ..
  // the same object is passed through the chain, should be curried instead but for now it's fine, perhaps TODO?
  const thresholds = (policy.thresholds || []).map((threshold) => {
    const transformedThresholdProperties = deserializeThresholdProperties(threshold);
    const transformedActivate = deserializeThresholdActivate(transformedThresholdProperties, policy);
    const transformedMitigations = deserializeThresholdMitigations(transformedActivate);

    return deserializeThresholdConditions(transformedMitigations, policy);
  });

  return {
    ...policy,
    thresholds
  };
}

// SERIALIZE END

function serialize(policy, removeMitigations) {
  const thresholds = (policy.thresholds || [])
    .filter((threshold) => threshold.enabled)
    .map((threshold) => {
      const prunedThreshold = pruneThresholdProperties(threshold);
      const serializedThresholdProperties = serializeThresholdProperties(prunedThreshold);
      const serializedActivate = serializeThresholdActivate(serializedThresholdProperties);
      const serializedConditions = serializeThresholdConditions(serializedActivate, policy);
      const serializedThreshold = serializeThresholdMitigations(serializedConditions, policy);

      if (serializedThreshold.notificationChannels && Array.isArray(serializedThreshold.notificationChannels)) {
        serializedThreshold.notificationChannels = serializedThreshold.notificationChannels.map(
          (notificationChannel) =>
            typeof notificationChannel === 'object' && notificationChannel !== null
              ? notificationChannel.id
              : notificationChannel
        );
      }

      if (removeMitigations || serializedThreshold.mitigations === '') {
        delete serializedThreshold.mitigations;
      }

      if (!serializedThreshold.filters) {
        serializedThreshold.filters = null;
      }

      // Enable baseline backfill if an enabled policy has a baseline condition
      if (
        policy.status === 'active' &&
        Array.isArray(serializedThreshold?.conditions) &&
        serializedThreshold.conditions.some(({ type }) => ['baseline', 'baselinePercent', 'keyNotInTop'].includes(type))
      ) {
        policy.baselineBackfillImmediately = true;
      }

      return serializedThreshold;
    });

  return {
    ...policy,
    thresholds
  };
}

export default {
  serialize,
  deserialize
};
