import $dictionary from 'app/stores/$dictionary';
import { SERIALIZE_FILTER_OPERATOR_MAP, DESERIALIZE_FILTER_OPERATOR_MAP } from '@kentik/ui-shared/alerting/constants';

function deserializeFilter(filter) {
  if (DESERIALIZE_FILTER_OPERATOR_MAP[filter.operator]) {
    filter.operator = DESERIALIZE_FILTER_OPERATOR_MAP[filter.operator];
  }

  filter.filterValue = filter.filterValue || '';

  return filter;
}

function deserializeSavedFilters(filterGroup) {
  if (filterGroup.savedFilters) {
    filterGroup.saved_filters = filterGroup.savedFilters.map(({ id, not }) => ({
      filter_id: id,
      is_not: not
    }));
    delete filterGroup.savedFilters;
  }
}

function deserializeFilterGroup(filterGroup) {
  const connectors = { all: 'All', any: 'Any' };

  const defaultFilterGroup = {
    autoAdded: '',
    name: '',
    named: false,
    not: false,
    filters: [],
    filterGroups: [],
    saved_filters: []
  };

  if (connectors[filterGroup.connector]) {
    filterGroup.connector = connectors[filterGroup.connector];
  }

  if (filterGroup.filters) {
    filterGroup.filters = filterGroup.filters.map(deserializeFilter);
  }

  if (filterGroup.filterGroups) {
    filterGroup.filterGroups = filterGroup.filterGroups.map(deserializeFilterGroup);
  }

  deserializeSavedFilters(filterGroup);

  return { ...defaultFilterGroup, ...filterGroup };
}

function serializeFilter(filter) {
  const filterValidators = $dictionary.get('queryFilters.validators') || {};
  const matchingValidator = filterValidators[filter.filterField];

  filter.operator = SERIALIZE_FILTER_OPERATOR_MAP[filter.operator] || filter.operator;
  filter.filterValue = `${filter.filterValue ?? ''}`;

  // While DE queries support numbers with commas, the policy API does not!
  if (matchingValidator && matchingValidator.includes('base:num+')) {
    filter.filterValue = filter.filterValue.replace(',', '');
  }

  return filter;
}

function serializeSavedFilters(filterGroup) {
  if (filterGroup.saved_filters) {
    filterGroup.savedFilters = filterGroup.saved_filters.map(({ filter_id, is_not }) => ({
      id: filter_id,
      not: is_not
    }));
    delete filterGroup.saved_filters;
  }
}

function serializeFilterGroup(filterGroup, isRootFilter) {
  const connectors = { All: 'all', Any: 'any' };

  const defaultFilterGroup = {
    autoAdded: '',
    name: '',
    named: false,
    not: false,
    filters: [],
    filterGroups: [],
    savedFilters: []
  };

  if (connectors[filterGroup.connector]) {
    filterGroup.connector = connectors[filterGroup.connector];
  }

  if (filterGroup.filters) {
    filterGroup.filters = filterGroup.filters.map(serializeFilter);
  }

  if (filterGroup.filterGroups) {
    filterGroup.filterGroups = filterGroup.filterGroups.map(serializeFilterGroup);
  }

  serializeSavedFilters(filterGroup);

  return isRootFilter ? filterGroup : { ...defaultFilterGroup, ...filterGroup };
}

function deserialize(policy) {
  const { filters = {}, ...restPolicy } = policy;
  const deserializedFilters = deserializeFilterGroup(filters);

  if (restPolicy?.metricConfig) {
    restPolicy.metricConfig.filters = deserializedFilters;
  }

  return {
    ...restPolicy,
    filters: deserializedFilters
  };
}

function serialize(policy) {
  const { filters = {}, ...restPolicy } = policy;

  return {
    ...restPolicy,
    filters: serializeFilterGroup(filters, true)
  };
}

export default {
  serializeFilterGroup,
  deserializeFilterGroup,
  serialize,
  deserialize,
  // for testing purposes
  serializeFilter
};
