import Validator from 'validatorjs';
import { memoize } from 'lodash';

import NameAndPlanInput from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/NameAndPlanInput';
import ProvidersAndFeatures from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/ProvidersAndFeatures';

import { trimTransform } from './utils';
import { AWS_EXPORT_ADDITIONAL_STEPS, AWS_TERRAFORM_CONFIG_STEP } from './aws/steps';
import { GCP_EXPORT_ADDITIONAL_STEPS } from './gcp/steps';
import {
  AZURE_EXPORT_ADDITIONAL_STEPS,
  AZURE_TERRAFORM_CONFIG_STEP,
  AZURE_POWERSHELL_CONFIG_STEP
} from './azure/steps';
import { OCI_EXPORT_ADDITIONAL_STEPS } from './oci/steps';

// step enums
export const PROVIDER_AND_FEATURE_SELECTION_STEP = 'providers-and-features';
export const CLOUD_PROVIDER_CONFIG_STEP = 'provider-configuration';
export const KENTIK_EXPORT_CONFIG_STEP = 'kentik-cloud-export-config';
export const TOTAL_VERIFICATION_STEP = 'verification';
export const NAME_DESCRIPTION_PLAN_STEP = 'name-description-plan';
export const KENTIK_PLAN_STEP = 'select-kentik-plan';

const PROVIDER_SELECTION_STEP = {
  id: PROVIDER_AND_FEATURE_SELECTION_STEP,
  title: 'Provider and Features',
  component: ProvidersAndFeatures,
  fields: {
    cloud_provider: {
      defaultValue: '',
      rules: 'allowed_cloud_provider|required',
      label: 'Cloud Provider'
    },
    'properties.enable_flow_log_collection': {
      defaultValue: false,
      label: 'Flow log collection',
      rules: 'observability_feature_selected',
      helpText: 'For network traffic use cases (e.g. Data Explorer queries, traffic analysis, etc.)'
    },
    'properties.aws_delete_after_read': {
      defaultValue: false,
      label: 'Delete after read'
    },
    'properties.enable_metrics_collection': {
      defaultValue: false,
      label: 'Cloud metrics history',
      helpText: 'For historical telemetry analysis, trending & alerting use cases'
    },
    'properties.gcp_cloud_run': {
      defaultValue: false,
      label: 'GCP Cloud Run Log Collection',
      helpText: 'Please note that selecting this option will create a second cloud export dedicated to Cloud Run logs'
    },
    'properties.enable_firewall_log_collection': {
      defaultValue: false,
      label: 'Firewall Log Collection',
      helpText: ''
    },
    useTerraform: {
      defaultValue: false,
      label: 'Help me configure my provider via Terraform'
    },
    usePowershell: {
      defaultValue: false
    },
    metadata_collection: {
      defaultValue: true,
      rules: 'observability_feature_selected',
      label: 'Metadata collection'
    },
    'properties.skip_metadata_collection': {
      defaultValue: false
    }
  },
  registerValidators: (form) => {
    Validator.register(
      'allowed_cloud_provider',
      (value) => ['aws', 'gcp', 'azure', 'oci'].includes(value),
      'Unsupported cloud provider. '
    );
    Validator.register(
      'observability_feature_selected',
      () => form.getValue('metadata_collection') || form.getValue('properties.enable_flow_log_collection'),
      'If metadata collection is disabled you must select flow log collection for this cloud export'
    );
  }
};

export const EXPORT_INITIAL_STEPS = [
  PROVIDER_SELECTION_STEP,
  {
    id: KENTIK_EXPORT_CONFIG_STEP,
    title: 'Kentik Export Configuration'
  },
  {
    id: NAME_DESCRIPTION_PLAN_STEP,
    title: 'Final Steps'
  }
];

export const EXPORT_COMMON_FINAL_STEP = {
  id: KENTIK_PLAN_STEP,
  title: 'Final Step',
  component: NameAndPlanInput,
  generateFields: memoize(
    () => ({
      name: {
        label: 'Cloud Export Name',
        rules: 'required',
        transform: {
          out: trimTransform
        },
        defaultValue: ''
      },
      'properties.description': {
        label: 'Description',
        defaultValue: ''
      },
      plan_id: {
        label: 'Billing Plan',
        rules: 'required',

        placeholder: 'Select a Plan',
        messages: {
          required: 'The "Billing Plan" field is required'
        }
      }
    }),
    ($cloudExportWizard) =>
      `${$cloudExportWizard.selectedCloudProvider}-${+$cloudExportWizard.isFlowLogCollectionEnabled}`
  )
};

export const COMPLETE_AWS_EXPORT_STEPS = [
  PROVIDER_SELECTION_STEP,
  ...AWS_EXPORT_ADDITIONAL_STEPS,
  EXPORT_COMMON_FINAL_STEP
];

export const COMPLETE_AWS_EXPORT_STEPS_WITH_TERRAFORM = [PROVIDER_SELECTION_STEP, AWS_TERRAFORM_CONFIG_STEP];

export const COMPLETE_GCP_EXPORT_STEPS = [
  PROVIDER_SELECTION_STEP,
  ...GCP_EXPORT_ADDITIONAL_STEPS,
  EXPORT_COMMON_FINAL_STEP
];
export const COMPLETE_AZURE_EXPORT_STEPS = [
  PROVIDER_SELECTION_STEP,
  ...AZURE_EXPORT_ADDITIONAL_STEPS,
  EXPORT_COMMON_FINAL_STEP
];

export const COMPLETE_AZURE_EXPORT_STEPS_WITH_TERRAFORM = [
  PROVIDER_SELECTION_STEP,
  AZURE_TERRAFORM_CONFIG_STEP,
  ...AZURE_EXPORT_ADDITIONAL_STEPS,
  EXPORT_COMMON_FINAL_STEP
];

export const COMPLETE_AZURE_EXPORT_STEPS_WITH_POWERSHELL = [
  PROVIDER_SELECTION_STEP,
  ...AZURE_EXPORT_ADDITIONAL_STEPS,
  AZURE_POWERSHELL_CONFIG_STEP,
  EXPORT_COMMON_FINAL_STEP
];

export const COMPLETE_OCI_EXPORT_STEPS = [
  PROVIDER_SELECTION_STEP,
  ...OCI_EXPORT_ADDITIONAL_STEPS,
  EXPORT_COMMON_FINAL_STEP
];

export const EXPORT_INITIAL_STEPS_IDS = EXPORT_INITIAL_STEPS.map((step) => step.id);
