import React from 'react';
import { computed } from 'mobx';
import api from 'core/util/api';
import { showSuccessToast } from 'core/components/toast';
import $auth from 'app/stores/$auth';

import Flex from 'core/components/Flex';
import { NECESSARY_RBAC_ROLES_FOR_SUBSCRIPTIONS } from 'shared/rbac/constants';
import $rbac from 'app/stores/rbac/$rbac';
import SubscriptionCollection from './SubscriptionCollection';

class SubscriptionStore {
  collection = new SubscriptionCollection();

  @computed
  get subscriptions() {
    return this.hasAllNeededRbacPermissions ? this.collection.models : this.ownedSubscriptions;
  }

  /**
   * Returns only subscriptions created by the currently active user.
   * @returns {import('./Subscription').default[]}
   */
  @computed
  get ownedSubscriptions() {
    return this.collection.models.filter((model) => model.get('user_id') === $auth.activeUser.id);
  }

  @computed
  get subscriptionsValues() {
    return this.subscriptions.map((model) => model.get('id'));
  }

  getSubscriptionsOptions(filterFn) {
    const models = filterFn ? this.subscriptions.filter(filterFn) : this.subscriptions;
    return models.map((model) => ({
      value: model.get('id'),
      label: (
        <Flex justifyContent="space-between" alignItems="center" flex={1}>
          {model.get('title')}
        </Flex>
      ),
      filterLabel: model.get('title'),
      model
    }));
  }

  /**
   * Used to determine whether you have sufficient RBAC permissions to subscribe to an arbitrary subscription.
   * This is necessary as otherwise subscriptions could allow you to view information which you are not supposed
   * to see.
   *
   * In future we may want to add more granular controls however currently this is considered YAGNI
   * and we simply block all subscriptions if there is a risk associated.
   * @returns {boolean}
   */
  @computed
  get hasAllNeededRbacPermissions() {
    return $rbac.hasUnrestrictedPermissions(NECESSARY_RBAC_ROLES_FOR_SUBSCRIPTIONS);
  }

  isUserSubscribedToSubscription(userEmail, model) {
    return !!(model.allRecipients || []).find((email) => email === userEmail);
  }

  // This will modify the collection given, adding an 'isSubscribed' attr to each model
  applySubscriptionsToContentCollection(collection, userEmail) {
    collection.unfiltered.forEach((model) => {
      const contentType = model.get('type').replace(/-/g, '');
      const isSubscribed = this.userSubscribedToContentCheck(userEmail, contentType, model.get('id'));
      const numSubscribers = this.getNumSubscribersOfContent(contentType, model.get('id'));
      model.set('isSubscribed', isSubscribed);
      model.set('numSubscribers', numSubscribers);
    });
    return collection;
  }

  userSubscribedToContentCheck(userEmail, contentType, contentId) {
    let isSubscribed = false;

    this.collection.models.every((model) => {
      if (
        model.get('content_type') === contentType &&
        (model.isSummary ||
          !!(model.isMultiSelectable ? model.get('content_metadata.values') : [model.get('content_id')]).find(
            (id) => id === contentId
          )) &&
        !!(model.allRecipients || []).find((email) => email === userEmail)
      ) {
        isSubscribed = true;
        return false;
      }
      return true;
    });

    return isSubscribed;
  }

  async isUserSubscribedToContent(userEmail, contentType, contentId) {
    // or if currently fetching
    if (!this.collection.hasFetched || this.collection.isRequestActive('fetching')) {
      let isSubscribed = false;

      await this.collection
        .fetch()
        .then(() => (isSubscribed = this.userSubscribedToContentCheck(userEmail, contentType, contentId)));

      return isSubscribed;
    }

    return this.userSubscribedToContentCheck(userEmail, contentType, contentId);
  }

  getNumSubscriptionsOfContent(contentType, contentId) {
    return this.collection.models.filter(
      (model) =>
        model.get('content_type') === contentType &&
        !!(model.isMultiSelectable ? model.get('content_metadata.values') : [model.get('content_id')]).find(
          (id) => id === contentId
        )
    ).length;
  }

  getNumSubscribersOfContent(contentType, contentId) {
    return new Set(
      this.collection.models
        .filter(
          (model) =>
            model.get('content_type') === contentType &&
            !!(model.isMultiSelectable ? model.get('content_metadata.values') : [model.get('content_id')]).find(
              (id) => id === contentId
            )
        )
        .flatMap((model) => model.allRecipients || [])
    ).size;
  }

  subscribeToNew(payload) {
    return api.post('/api/ui/subscriptions/subscribe', { data: payload }).then(
      (model) => {
        if (this.collection) {
          this.collection.add(model);
        }

        showSuccessToast(`Subscription "${model.title}" was added successfully`);
        return model;
      },
      (error) => {
        throw error;
      }
    );
  }

  subscribeToExisting(id) {
    const model = this.collection.get(id);
    model.setRequestStatus('updating');
    return api.post(`/api/ui/subscriptions/${id}/subscribe`).then(
      (updatedModel) => {
        model.setRequestStatus(null);

        if (this.collection) {
          this.collection.remove(id);
          this.collection.add(updatedModel);
        }

        showSuccessToast(`Subscription "${updatedModel.title}" was subscribed to successfully`);
        return updatedModel;
      },
      (error) => {
        if (model) {
          model.setRequestStatus(null);
        }
        throw error;
      }
    );
  }

  unsubscribe(id) {
    const model = this.collection.get(id);
    model.setRequestStatus('updating');
    return api.post(`/api/ui/subscriptions/${id}/unsubscribe`).then(
      (updatedModel) => {
        model.setRequestStatus(null);

        let title;
        if (this.collection) {
          title = this.collection.get(id).get('title');
          this.collection.remove(id);
          if (updatedModel?.id) {
            this.collection.add(updatedModel);
          }
        }

        showSuccessToast(`Subscription "${title}" was unsubscribed to successfully`);
        return updatedModel;
      },
      (error) => {
        if (model) {
          model.setRequestStatus(null);
        }
        throw error;
      }
    );
  }

  runSubscription(id) {
    const model = this.collection.get(id);
    showSuccessToast(`Subscription "${model.get('title')}" is scheduled to run.`);
    return api.get(`/api/ui/subscriptions/${model.id}/run`);
  }
}

export default new SubscriptionStore();
