import { EVENT_POLICY_METRICS } from '@kentik/ui-shared/alerting/constants';
import { EVENT_TYPE_OPTIONS } from 'app/forms/config/kevent/constants';
import { TARGET_TYPES } from 'app/forms/config/nms/constants';
import { isEqual } from 'lodash';
import PolicyModel from '../PolicyModel';
import { transformFormValuesToEventPolicy, getDefaultEventAppProtoFilter } from '../policyUtils';
import NmsPolicyModel from '../nms/NmsPolicyModel';

class EventPolicyModel extends PolicyModel {
  get urlRoot() {
    return '/api/ui/alerting/policies/kevent';
  }

  get metrics() {
    if (this.isSyslogEventPolicy) {
      return [EVENT_POLICY_METRICS.SYSLOG];
    }

    if (this.isSnmpTrapEventPolicy) {
      return [EVENT_POLICY_METRICS.SNMP_TRAP];
    }

    return this.get('metrics');
  }

  get targetType() {
    return TARGET_TYPES.EVENT;
  }

  get filters() {
    const allPolicyFilters = this.get('filters');

    if (allPolicyFilters.filterGroups.length !== 1) {
      return allPolicyFilters;
    }

    // If no policy filter is provided at creation, we add our own "timestamp > 0" filter in transformEventPolicy.
    // This reduces the data processed by a policy by scoping it to the right AppProtocol.
    // However, we don't want this filter showing to users, or being applied to chart queries.
    // So we strip it out here, and make sure to access filters via this getter.

    const appProtoFilter = getDefaultEventAppProtoFilter(this.get('dimensions'));
    const userFilterGroups = allPolicyFilters.filterGroups.filter(({ filters }) => {
      const hasOneFilter = filters.length === 1;
      const hasTimestampFilter = isEqual(filters[0], appProtoFilter);

      // Only reject the filter group if it has a single filter, and that filter is the appProtoFilter
      return !(hasOneFilter && hasTimestampFilter);
    });

    return { ...allPolicyFilters, filterGroups: userFilterGroups };
  }

  clonePolicy(overrides = {}) {
    const { id, ...restPolicy } = this.get();
    const clonedPolicy = new this.constructor(restPolicy);

    clonedPolicy.set({
      name: `${this.attributes.name} (Clone)`,
      thresholds: this.attributes.thresholds.map(({ thresholdID, policyID, ...threshold }) => threshold),
      activationSettings: Object.assign({}, this.attributes.activationSettings, { alertManagerConfig: {} }),
      minTrafficValue: 0,
      ...overrides
    });

    return clonedPolicy;
  }

  getPolicyFormValues = () => {
    // If the policy has existing kevent form data, then we're either
    // cloning or editing a policy.
    const keventFormData = this.getApplicationMetadata('kevent');
    const explorerPolicyData = this.get('explorerPolicy');
    const nmsPolicyModel = new NmsPolicyModel();

    if (keventFormData) {
      return { ...keventFormData, id: this.id };
    }

    if (explorerPolicyData) {
      const { filters = {}, dimensions, eventType } = explorerPolicyData;
      const eventTypeLabel = EVENT_TYPE_OPTIONS.find(({ value }) => eventType === value)?.label;

      this.set('name', `${eventTypeLabel} Alert Policy`);
      const explorerFormData = nmsPolicyModel.get();
      explorerFormData.target.uiProperties = { eventType, targetType: this.targetType };
      explorerFormData.target.entity_type = this.targetType;
      explorerFormData.target.custom = { dimensions };
      explorerFormData.scope = { ...explorerFormData.scope, ...filters };
      return explorerFormData;
    }

    return nmsPolicyModel.get();
  };

  buildPolicyFromFormValues = (formValues = {}, passedOverrides) => {
    if (this.isNew) {
      return transformFormValuesToEventPolicy(formValues);
    }

    return transformFormValuesToEventPolicy(formValues, passedOverrides || this.get());
  };
}

export default EventPolicyModel;
