import { computed } from 'mobx';
import moment from 'moment';
import Model from 'core/model/Model';
import { uniqBy } from 'lodash';

import {
  ALERT_SEVERITY_COLORS,
  ALERT_STATE_ICONS,
  ALERT_STATE_COLORS,
  ALERT_SEVERITY_LABELS,
  ALERT_ACKSTATES,
  ALERT_ACKSTATE_LABELS,
  ALERT_STATE_LABELS,
  POLICY_APPLICATION_LABELS,
  POLICY_APPLICATIONS,
  ALARM_STATE_RAW_TO_KEY,
  ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY,
  ALERT_BASELINE_REASONS,
  ALERT_BASELINE_REASONS_MAP,
  PERCENTAGE_METRICS,
  LATENCY_METRICS,
  MAPPED_METRICS,
  METRIC_LABELS
} from 'shared/alerting/constants';

import safelyParseJSON from 'core/util/safelyParseJson';
import { getMeasurementModelDataFromPolicy, getPolicyModelFromAttributes } from 'app/util/policies';
import formatMetricValue from 'app/util/formatMetricValue';

import $auth from 'app/stores/$auth';
import $metrics from 'app/stores/metrics/$metrics';
import $devices from 'app/stores/device/$devices';
import $alerting from 'app/stores/alerting/$alerting';
import $mkp from 'app/stores/mkp/$mkp';
import $sites from 'app/stores/site/$sites';

import MetricDeviceModel from 'app/stores/metrics/MetricDeviceModel';
import { getNmsAlarmContext } from '@kentik/ui-shared/nms/alerts';
import PolicyModel from './PolicyModel';
import NmsPolicyModel from './nms/NmsPolicyModel';
import EventPolicyModel from './kevent/EventPolicyModel';

import {
  isDeviceStateChangePolicy,
  isInterfaceStateChangePolicy,
  isBGPNeighborStateChangeAlert,
  isCustomStateChangeAlert,
  isSyslogEventPolicy,
  isSnmpTrapEventPolicy
} from './policyUtils';

export default class AlertModel extends Model {
  get urlRoot() {
    return '/api/ui/alertingManager/alarms';
  }

  @computed
  get duration() {
    let endTime = this.get('end_time') ? moment(this.get('end_time')) : moment();
    const startTime = moment(this.get('start_time'));
    if (endTime.isBefore(startTime)) {
      endTime = moment();
    }
    return moment.duration(endTime - startTime).asMilliseconds();
  }

  get startTime() {
    return this.get('start_time');
  }

  get endTime() {
    const startTime = this.get('start_time');
    const endTime = this.get('end_time');

    // For some reason, the API sends a bogus end time (namely, 1 AD) if an alarm is active,
    // rather than omitting it or using null. So we have to correct it here.
    if (moment(endTime).isBefore(moment(startTime))) {
      return undefined;
    }

    return endTime;
  }

  get eventEndTime() {
    const { startTime, endTime, threshold } = this;
    const { gracePeriod = 0 } = threshold?.activate || {};

    if (!endTime) {
      return null;
    }

    const eventEndTime = moment(endTime).subtract(gracePeriod, 'minutes');
    return eventEndTime.isBefore(moment(startTime)) ? startTime : eventEndTime;
  }

  get displayDuration() {
    return moment.duration(this.duration).humanize({ m: 60 });
  }

  get severityKey() {
    const severity = this.get('severity');
    return ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY[severity] || severity;
  }

  get id() {
    return this.get('id');
  }

  // Deprecating this getter
  get severity() {
    return ALERT_SEVERITY_LABELS[this.severityKey] || 'Unknown';
  }

  // Use this instead of severity
  get severityLabel() {
    return ALERT_SEVERITY_LABELS[this.severityKey] || 'Unknown';
  }

  get severityColor() {
    return ALERT_SEVERITY_COLORS[this.severityKey];
  }

  // Alert capabilities

  @computed
  get canSuppress() {
    return !this.isEventPolicy;
  }

  @computed
  get canSilence() {
    return true;
  }

  @computed
  get canAck() {
    return true;
  }

  @computed
  get isActive() {
    return this.stateKey === 'alarm';
  }

  @computed
  get isCleared() {
    return this.stateKey === 'clear';
  }

  get autoAckId() {
    return this.get('ack.auto_ack_id');
  }

  @computed
  get isAutoAcked() {
    return this.autoAckId && this.autoAckId !== '';
  }

  @computed
  get isAcked() {
    return this.get('ack_state') === ALERT_ACKSTATES.DONE;
  }

  @computed
  get isNotAcked() {
    return this.get('ack_state') === ALERT_ACKSTATES.NOT_ACKED;
  }

  @computed
  get isAckReq() {
    return this.get('ack_state') === ALERT_ACKSTATES.REQUIRED;
  }

  get ackUserId() {
    const ackUserId = this.get('ack.acked_by_user');
    return !ackUserId || ackUserId === '0' ? null : ackUserId;
  }

  get ackTimestamp() {
    return this.get('ack.acked_at');
  }

  get isAlertManagerAlert() {
    return true;
  }

  @computed
  get stateKey() {
    return ALARM_STATE_RAW_TO_KEY[this.get('state')];
  }

  get stateLabel() {
    return ALERT_STATE_LABELS[this.stateKey] || 'Unknown';
  }

  get ackStateLabel() {
    return ALERT_ACKSTATE_LABELS[this.get('ack_state')] || 'Unknown';
  }

  get stateColor() {
    return this.stateKey === 'alarm' ? 'danger' : 'success';
  }

  get policy() {
    const policyModel = this.get('policy');

    if (policyModel) {
      return policyModel;
    }

    if (this.isNmsPolicy) {
      return new NmsPolicyModel();
    }

    if (this.isEventPolicy) {
      return new EventPolicyModel();
    }

    return new PolicyModel();
  }

  @computed
  get policyObject() {
    // a deserialized JSON object of the alert policy
    return this.policy.get();
  }

  get policyName() {
    return this.policy && this.policy.get('name');
  }

  get application() {
    return POLICY_APPLICATION_LABELS[this.get('application')];
  }

  @computed
  get dashboardQuery() {
    return $alerting.getDashboardQuery(this);
  }

  // Not applicable to NMS alerts
  // NMS alerts have levels, which have a different type and interpretation than non-NMS policy thresholds
  get threshold() {
    if (this.isNmsPolicy) {
      return null;
    }
    return this.policy?.thresholds?.find((threshold) => threshold.severity === this.severityKey);
  }

  get metrics() {
    return this.policy.metrics;
  }

  @computed
  get tenant() {
    const policyId = this.get('policy.id');
    const tenant = $mkp.tenants.subpolicyIdToTenant[policyId];
    return tenant ? tenant.get('name') : 'None';
  }

  hasMissingValue(onMetric) {
    if (!this.threshold) {
      return false;
    }

    const { conditions = [] } = this.threshold;

    return conditions.some((condition) => {
      const { type, direction, metric = this.primaryMetric } = condition;

      return type === 'keyNotInTop' && direction === 'historyToCurrent' && metric === onMetric;
    });
  }

  // Not applicable to NMS alerts
  // A given metric can be present in more than one NMS policy condition,
  // and the values associated with the metrics are complex objects (eg, state change)
  getValue(metric) {
    if (this.isNmsPolicy) {
      return null;
    }
    const value = this.get(`metricToValue.${metric}`);

    if (this.hasMissingValue(metric)) {
      return {
        metric: null,
        value: 'Missing',
        formatted: 'Missing',
        formattedValue: 'Missing',
        formattedMetric: null,
        toString() {
          return this.formatted;
        }
      };
    }

    let formattedValue;
    let formattedMetric;

    if (PERCENTAGE_METRICS.includes(metric)) {
      formattedValue = Math.abs(Math.round(value * 100));
      formattedMetric = `% ${value >= 0 ? 'increase' : 'decrease'}`;
    } else if (LATENCY_METRICS.includes(metric)) {
      ({ value: formattedValue, metric: formattedMetric } = formatMetricValue(value / 1000, 'latency'));
    } else {
      ({ value: formattedValue, metric: formattedMetric } = formatMetricValue(value, MAPPED_METRICS[metric] || metric));
    }

    if (METRIC_LABELS[metric]) {
      formattedMetric = METRIC_LABELS[metric];
    }

    const joiner = formattedMetric.startsWith('%') ? '' : ' ';

    return {
      metric,
      value,
      formatted: `${formattedValue}${joiner}${formattedMetric}`,
      formattedMetric,
      formattedValue,
      toString() {
        return this.formatted;
      }
    };
  }

  // Not applicable to NMS alerts
  // A given metric can be present in more than one NMS policy condition,
  // and the values associated with the metrics are complex objects (eg, state change)
  @computed
  get values() {
    if (this.isNmsPolicy) {
      return null;
    }
    const values = this.metrics.map((metric) => this.getValue(metric));

    return uniqBy(values, ({ formatted }) => formatted);
  }

  @computed
  get avgValues() {
    return this.values.filter(({ metric }) => metric.endsWith('_avg'));
  }

  @computed
  get flowSnmpIsNull() {
    const values = this.values.filter((value) => value.metric.endsWith('_is_null'));
    const valuesAreNull = (dir) => (value) => value.metric.startsWith(dir) && value.value === 1;

    return { ingress: values.some(valuesAreNull('ingress')), egress: values.some(valuesAreNull('egress')) };
  }

  @computed
  get primaryValue() {
    const overTimeMetric = this.metrics.find((m) => m.endsWith('_day_over_day') || m.endsWith('_week_over_week'));

    if (overTimeMetric) {
      return this.getValue(overTimeMetric);
    }

    return this.metrics.length > 0 ? this.getValue(this.metrics[0]) : null;
  }

  // Not applicable to NMS alerts
  // This returns a map of Record<MetricName (string), MetricValue (string | number)> for each policy metric
  // This is different from NMS policies in a few ways:
  // - There are no top-level metrics defined for an NMS policy
  // - An NMS policy can have multiple lists of conditions, and a metric may be present in more than one condition
  // - The value associated with a metric in an NMS policy condition is a complex object representing a state the metric is in, a state it is changing to, or a value with a comparison operator
  get metric() {
    if (this.isNmsPolicy) {
      return null;
    }
    const { metrics } = this.policy;
    const returnVal = {};

    metrics.forEach((metric) => {
      const condition = this.threshold.conditions.find((c) => c.metric === metric);

      if (condition !== undefined) {
        returnVal[metric] = condition.comparisonValue;
      }
    });

    return returnVal;
  }

  get sortedMetrics() {
    // We're overriding with a single metric if this is an event policy
    if (this.isEventPolicy) {
      return this.policy.metrics;
    }

    const policyMetrics = this.policy.metrics || [];
    const alertMetrics = this.get('metrics') || [];
    const metrics = [];
    const primaryMetric = policyMetrics[0];

    // Prefer sort order from policy, but it's possible policy has changed since alert fired.
    alertMetrics.forEach((metric) => {
      metrics[metric === primaryMetric ? 'unshift' : 'push'](metric);
    });

    return metrics;
  }

  get primaryMetric() {
    return this.get('metrics')?.[0] || 'bits';
  }

  // Not applicable to NMS alerts (multiple target measurements)
  @computed
  get policyMeasurement() {
    if (!this.isMetricPolicy) {
      return undefined;
    }

    const policy = this.policy.get();
    const { fullMeasurement } = getMeasurementModelDataFromPolicy({
      ...policy,
      // Need metadata in order to dynamically retrieve the measurement
      applicationMetadata: safelyParseJSON(policy.applicationMetadata)
    });
    return fullMeasurement;
  }

  @computed
  get isMetricPolicy() {
    return this.get('application') === POLICY_APPLICATIONS.METRIC;
  }

  @computed
  get isNmsPolicy() {
    return this.get('application') === POLICY_APPLICATIONS.NMS;
  }

  @computed
  get isEventPolicy() {
    return this.get('application') === POLICY_APPLICATIONS.KEVENT;
  }

  @computed
  get isSyslogEventPolicy() {
    return this.isEventPolicy && isSyslogEventPolicy(this.dimensionKeys);
  }

  @computed
  get isSnmpTrapEventPolicy() {
    return this.isEventPolicy && isSnmpTrapEventPolicy(this.dimensionKeys);
  }

  @computed
  get isDDoSPolicy() {
    return this.get('application') === POLICY_APPLICATIONS.DDOS;
  }

  @computed
  get isStateChangeAlert() {
    return this.isMetricPolicy && this.policy.isToggleMode;
  }

  @computed
  get isDeviceStateChangeAlert() {
    return this.isStateChangeAlert && isDeviceStateChangePolicy(this.policy);
  }

  @computed
  get isInterfaceStateChangeAlert() {
    return this.isStateChangeAlert && isInterfaceStateChangePolicy(this.policy);
  }

  @computed
  get isBGPNeighborStateChangeAlert() {
    return this.isStateChangeAlert && isBGPNeighborStateChangeAlert(this.policy);
  }

  @computed
  get isCustomStateChangeAlert() {
    return this.isStateChangeAlert && isCustomStateChangeAlert(this.policy);
  }

  @computed
  get isUpDownPolicy() {
    return this.policyObject?.applicationMetadata?.type === 'state-change';
  }

  @computed
  get policySubtype() {
    return this.isUpDownPolicy && this.policyObject?.applicationMetadata?.subtype;
  }

  @computed
  get isDeviceUpDownPolicy() {
    return this.isUpDownPolicy && this.policyObject?.applicationMetadata?.subtype === 'devices';
  }

  @computed
  get isInterfaceUpDownPolicy() {
    return this.isUpDownPolicy && this.policyObject?.applicationMetadata?.subtype === 'interfaces';
  }

  @computed
  get isBgpNeighborPolicy() {
    return this.isUpDownPolicy && this.policyObject?.applicationMetadata?.subtype === 'bgp_neighbors';
  }

  @computed
  get policyChanged() {
    const startTime = this.get('start_time');
    let lastEdit = this.policyObject?.lastEditTime;

    if (this.isNmsPolicy) {
      // The policy attached to the alert model is the most recent version of the policy; not necessarily the version at the time of the alert.
      // NMS policies get a new version every time they are edited.
      // To check if the policy has changed, see if the most recent version creation date is more recent than the alert start.
      lastEdit = this.policy?.versionCreatedAt;
    }

    return lastEdit && startTime ? lastEdit > startTime : false;
  }

  @computed
  get alertDetailLink() {
    return `/v4/alerting/${this.id}`;
  }

  get activationContext() {
    return this.get('activation_context');
  }

  /**
   * Returns a representation of the NMS alarm context event view model.
   * Returns null for non-NMS alerts (any alerts associated with policies whose type is not "nms");
   * @type {import('@kentik/ui-shared/nms/alerts.js').NmsAlarmContext | null} nmsAlarmContext
   */
  @computed
  get nmsAlarmContext() {
    if (!this.isNmsPolicy) {
      return null;
    }
    return getNmsAlarmContext(this.activationContext?.event_view_model);
  }

  get alarmTrigger() {
    const baselineSource = this.get('baselineSource');
    // 0 equates to 'ACT_NOT_USED_BASELINE', meaning no baseline info found
    return ALERT_BASELINE_REASONS_MAP[baselineSource || 0];
  }

  get alarmTriggerText() {
    return ALERT_BASELINE_REASONS[this.alarmTrigger]?.message || null;
  }

  get dimensionKeys() {
    return this.policy.dimensions || [];
  }

  get mitigationID() {
    return this.get('mitigationID');
  }

  @computed
  get dimensions() {
    return this.dimensionKeys
      .map((dimension) => $alerting.getDimension(this, dimension))
      .filter((d) => d && d.value !== undefined && d.value !== '');
  }

  // Not applicable to NMS alerts (multiple target dimensions, either explicit or via entity type)
  @computed
  get primaryDimension() {
    if (this.isNmsPolicy) {
      return null;
    }
    return this.dimensionKeys.length > 0 ? $alerting.getDimension(this, this.dimensionKeys[0]) : null;
  }

  // Not applicable to NMS alerts (multiple target dimensions, either explicit or via entity type)
  @computed
  get primary_dimension() {
    return this.primaryDimension;
  }

  get baselineValue() {
    return this.get('baselineValue');
  }

  get baselineSource() {
    return this.get('baselineSource');
  }

  get metricToValue() {
    return this.get('metricToValue');
  }

  @computed
  get stateText() {
    return ALERT_STATE_LABELS[this.stateKey];
  }

  get statusColor() {
    return ALERT_STATE_COLORS[this.stateKey];
  }

  get status() {
    return ALERT_STATE_LABELS[this.stateKey];
  }

  get statusIcon() {
    return ALERT_STATE_ICONS[this.stateKey];
  }

  get severityDisplay() {
    return ALERT_SEVERITY_LABELS[this.severityKey] || 'Unknown';
  }

  @computed
  get stateIcon() {
    return $alerting.getStateIcon(this.stateKey);
  }

  // METRICS CHART STUFF

  @computed
  get dimensionToKeyPart() {
    return this.dimensionToValue;
  }

  @computed
  get dimensionToValue() {
    return this.get('dimensionToKeyPart') || {};
  }

  @computed
  get dimensionToKeyDetail() {
    return this.get('dimensionToKeyDetail');
  }

  @computed
  get reconDimensions() {
    const dimensionObject = {};
    const measurementModel = $metrics.measurementModel(this.reconMeasurement);

    if (measurementModel) {
      Object.entries(this.dimensionToValue).forEach(([dimKey, dimValue]) => {
        const baseDimension = dimKey.replace($metrics.getAppProtocolDimension(this.reconMeasurement), '');

        const keyMatch = Object.keys(measurementModel.storage.Dimensions).find(
          (key) => measurementModel.storage.Dimensions[key].Column === baseDimension
        );

        dimensionObject[keyMatch || baseDimension] = dimValue;
      });
    }

    // Use Map to ensure order.
    const dimensions = {};
    const orderedDimensions = ['device_name', 'if_interface_name'];

    orderedDimensions.forEach((dimension) => {
      if (dimensionObject[dimension]) {
        dimensions[dimension] = dimensionObject[dimension];
        delete dimensionObject[dimension];
      }
    });

    Object.entries(dimensionObject).forEach(([key, value]) => {
      if (!['km_device_id', 'km_measurement_name'].includes(key)) {
        dimensions[key] = value;
      }
    });

    return dimensions;
  }

  @computed
  get reconDevice() {
    // Both standard and native NMS alerts will almost always recon (NMS) devices
    // which are needed in order to display chart information.
    const deviceId = this.nmsAlarmContext?.groupKey?.device_id || this.get('device_id');
    const { device_name } = this.reconDimensions;
    let metricMatch;
    let summaryMatch;

    if (deviceId) {
      metricMatch = $metrics.deviceCollection.get(deviceId);
      summaryMatch = $devices.deviceSummariesById[deviceId];
    } else if (device_name) {
      metricMatch = $metrics.deviceModelByName(device_name);
      summaryMatch = $devices.deviceSummariesByName[device_name];
    }

    if (!metricMatch && !summaryMatch) {
      return null;
    }

    return metricMatch || new MetricDeviceModel({ id: summaryMatch.id, name: summaryMatch.device_name });
  }

  @computed
  get reconMeasurement() {
    return this.get('measurement');
  }

  getThreshold(metric = this.metrics[0]) {
    return $alerting.getThreshold(this, metric);
  }

  @computed
  get ruleId() {
    return this.get('rule_id');
  }

  @computed
  get silenceModel() {
    // Returns silence model or false
    return $alerting.findActiveSilenceForAlert(this);
  }

  // Returns an object of the basic elements needed to identify an
  // alert series for suppressing, silencing, or acknowledging.
  get pattern() {
    const pattern = {
      application: this.policy.application,
      policyId: `${this.policy.id}`,
      ruleId: this.ruleId,
      dimensionToKeyPart: { ...(this.get('dimensionToKeyPart') || {}) }
    };

    // Drop dimensions with blank or omitted values
    Object.keys(pattern.dimensionToKeyPart).forEach((dimension) => {
      const value = pattern.dimensionToKeyPart[dimension];
      if (value === '' || value === undefined || value === '---') {
        delete pattern.dimensionToKeyPart[dimension];
      }
    });

    // Add measurement-based info if applicable
    if (this.isMetricPolicy) {
      const measurement = this.get('measurement');
      const device_id = this.get('device_id');
      // Measurement & device_id not included in dimensionToKeyPart, so we add it here
      const appProtoPrefix = $metrics.getAppProtocolDimension(measurement);
      pattern.measurement = measurement;
      pattern.dimensionToKeyPart[`${appProtoPrefix}km_measurement_name`] = measurement;
      if (device_id) {
        pattern.dimensionToKeyPart[`${appProtoPrefix}km_device_id`] = device_id;
      }
    }

    if (this.isNmsPolicy) {
      // Even though Native NMS policies may have multiple measurements,
      // providing a measurement here is still useful since every
      // dimension on a native NMS policy must be present on all its measurements.
      // In order to do label lookups (ex: in suppressions) for dimensions,
      // we need a valid measurement, and any associated measurement will do.
      pattern.measurement = this.nmsAlarmContext?.policy?.target?.measurements?.[0];
    }

    return pattern;
  }

  setAcknowledged() {
    const ackConfig = this.get('ack');
    ackConfig.state = ALERT_ACKSTATES.DONE;
    ackConfig.acked_by_user = $auth.activeUser.id;
    ackConfig.acked_at = moment.utc();
    this.set('ack', ackConfig);
    this.set('ack_state', ALERT_ACKSTATES.DONE);
  }

  setUnacknowledged() {
    const ackConfig = this.get('ack');
    ackConfig.state = ALERT_ACKSTATES.NOT_ACKED;
    ackConfig.acked_by_user = '0';
    ackConfig.acked_at = null;
    this.set('ack', ackConfig);
    this.set('ack_state', ALERT_ACKSTATES.NOT_ACKED);
  }

  setCleared() {
    this.set('state', 'ALARM_STATE_CLEAR');
  }

  get dimensionValues() {
    return Object.values(this.dimensionToValue);
  }

  deserializeContext = (context) => {
    const contextItems = context?.event_view_model?.details || [];
    const contextData = { metricToValue: {} };

    contextItems.forEach(({ name, tag, value }) => {
      if (name === 'AlarmPolicyID') {
        contextData.policyId = value;
      }

      if (name === 'AlarmPolicyApplication') {
        contextData.application = value;
      }

      if (name === 'AlarmThresholdID') {
        contextData.thresholdId = value;
      }

      if (name === 'Baseline') {
        contextData.baselineValue = value;
      }

      if (name === 'AlarmBaselineSource') {
        contextData.baselineSource = value;
      }

      if (name === 'AlarmBaselineDescription') {
        contextData.baselineDescription = value;
      }

      if (name === 'AlarmPolicyApplicationMetadata') {
        const metadata = safelyParseJSON(value) || {};
        contextData.measurement = metadata.measurement;
      }

      if (tag === 'metric') {
        contextData.metrics = Array.isArray(contextData.metrics) ? [...contextData.metrics, name] : [name];
        contextData.metricToValue[name] = value;
      }
    });

    return contextData;
  };

  deserialize(data) {
    const { alarm = {}, policy, activation_context = {}, interfaceDescriptions } = data || {};
    const { key, ...alert } = alarm;
    const context = this.deserializeContext(activation_context);
    const dimensionToKeyPart = {};
    let measurement;
    let device_id;

    if (policy) {
      const alertPolicy = { ...policy };
      alert.policy = getPolicyModelFromAttributes(alertPolicy, true);
    } else {
      alert.policy = new PolicyModel({}, { deserialize: true });
    }

    Object.entries(key.value || {}).forEach(([dimKey, value]) => {
      if (dimKey.endsWith('km_measurement_name')) {
        measurement = value;
      } else if (dimKey.endsWith('km_device_id')) {
        device_id = value;
      } else {
        dimensionToKeyPart[dimKey] = value;
      }
    });

    const dimensionToKeyDetail = Object.keys(dimensionToKeyPart).reduce((acc, dimKey) => {
      if (dimKey === 'i_device_id') {
        const id = dimensionToKeyPart.i_device_id;
        const device = $devices.deviceSummariesById[id];
        acc.i_device_id = {
          device: {
            id,
            labels: device?.labels,
            name: device?.device_name
          }
        };
      }

      if (dimKey === 'InterfaceID_src' || dimKey === 'InterfaceID_dst') {
        const snmpID = dimensionToKeyPart[dimKey];
        const deviceId = dimensionToKeyPart.i_device_id;
        const intfDescriptions = interfaceDescriptions || this.collection.interfaceDescriptions || {};
        const intf = intfDescriptions[`${deviceId}|${snmpID}`];

        if (intf) {
          acc[dimKey] = {
            interface: {
              id: intf.id,
              snmpDescription: `${intf.interface_description} - ${intf.snmp_alias}`,
              snmpID
            }
          };
        }
      }

      if (dimKey === 'i_device_site_name') {
        const name = dimensionToKeyPart[dimKey];
        const siteId = $sites.sitesTitleMap[name];
        // alarms[0].dimensionToKeyDetail.i_device_site_name.site
        acc[dimKey] = {
          site: {
            id: siteId,
            name
          }
        };
      }

      return acc;
    }, {});

    return super.deserialize({
      ...alert,
      ...context,
      measurement,
      device_id,
      dimensionToKeyPart,
      dimensionToKeyDetail,
      interfaceDescriptions,
      activation_context
    });
  }
}
