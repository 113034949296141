import {
  getPolicyExpireDate,
  isSilentMode,
  getSilentModeDate,
  isInterfaceStateChangePolicy,
  getMaxKeysPerPolicy
} from 'app/stores/alerting/policyUtils';
import {
  INTERFACE_FILTER_MAP,
  POLICY_AGG_FUNCTIONS,
  POLICY_AGG_FUNCTIONS_DESERIALIZE,
  POLICY_APPLICATIONS,
  POLICY_MODES,
  POLICY_DEFAULT_VALUES
} from 'shared/alerting/constants';
import $dictionary from 'app/stores/$dictionary';
import $metrics from 'app/stores/metrics/$metrics';
import safelyParseJSON from 'core/util/safelyParseJson';
import { convertInterfacesToFilterGroup, getKmetricMeasurementFilterGroup } from 'app/util/filters';
import { getMeasurementModelDataFromPolicy, mapKmetricDimensions } from 'app/util/policies';
import { GREEK_METRICS, GREEK_FACTORS } from 'app/util/constants';

// Removes filters we don't want to show in the UI, and which will be rebuilt on serialize.
export function removeHiddenKmetricsFilters(policy, measurementModel) {
  const measurementName = measurementModel.get('measurement');
  const storage = measurementModel.get('storage') || {};
  const metadata = policy.applicationMetadata || {};
  const metadataInterfaces = metadata.interfaces || {};
  const interfaceKeys = Object.keys(metadataInterfaces);
  const operStatus = storage.Metrics?.['oper-status']?.Column;
  const adminStatus = storage.Metrics?.['admin-status']?.Column;
  const policyFilterGroups = policy.filters?.filterGroups || [];
  const kmetricsFiltersToRemove = [];
  const interfaceFiltersToRemove = [];
  const kmPrefix = $metrics.getAppProtocolDimension(measurementName, null, measurementModel);

  // Always remove these
  kmetricsFiltersToRemove.push(`${kmPrefix}km_measurement_name`);
  kmetricsFiltersToRemove.push('km_device_id');

  // ifOperStatusValueExists filter
  if (operStatus && isInterfaceStateChangePolicy(policy)) {
    kmetricsFiltersToRemove.push(`${kmPrefix}${operStatus}`);
  }

  // onlyAlertWhereAdminStatusIsUp filter (DEPRECATED: see https://github.com/kentik/ui-app/issues/21625)
  // Leaving this in to remove old filters from existing policies:
  if (adminStatus && metadata.onlyAlertWhereAdminStatusIsUp) {
    kmetricsFiltersToRemove.push(`${kmPrefix}${adminStatus}`);
  }

  // Handle separately to reduce iterations, and for stricter comparisons
  if (interfaceKeys.length > 0) {
    interfaceKeys.forEach((key) => {
      const interfaceValue = metadataInterfaces[key];
      let { dimension } = INTERFACE_FILTER_MAP[key] || {};

      // km_device_id is a special case; don't prefix it for filters
      if (dimension && dimension !== 'km_device_id') {
        dimension = `${kmPrefix}${storage.Dimensions?.[dimension]?.Column}`;
      }

      (Array.isArray(interfaceValue) ? interfaceValue : [interfaceValue]).forEach((intfVal) => {
        interfaceFiltersToRemove.push({
          filterField: dimension,
          filterValue: intfVal?.toString()
        });
      });
    });
  }

  // Now let's remove any matches
  return (
    policyFilterGroups
      .map((group) => {
        // kmetrics filters will only ever be a length of 1, so only check when that's the case
        if (group.filters.length === 1) {
          group.filters = kmetricsFiltersToRemove.some((filterField) => filterField === group.filters[0].filterField)
            ? []
            : [group.filters[0]];
        }

        if (interfaceFiltersToRemove.length > 0) {
          // Only remove group if every filter in it has a field+value match
          group.filters = group.filters.every((policyFilter) => {
            const { filterField, filterValue } = policyFilter;
            return interfaceFiltersToRemove.some((f) => f.filterField === filterField && f.filterValue === filterValue);
          })
            ? []
            : group.filters;
        }

        return group;
      })
      // Clean up empty filter groups
      .filter((group) => group.filters.length > 0)
  );
}

function transformToMinutes(duration) {
  const match = duration?.match(/^(\d+)(h|m|s)$/);
  const timeWindow = parseInt(match?.[1]) || 0;
  const shortTimeUnit = match?.[2] || 'm';

  // it's seconds - convert to minutes
  if (shortTimeUnit === 's') {
    return timeWindow / 60;
  }

  // it's already minutes
  if (shortTimeUnit === 'm') {
    return timeWindow;
  }

  // it's hours - convert to minutes
  if (shortTimeUnit === 'h') {
    return timeWindow * 60;
  }

  return duration;
}

function deserialize(policy) {
  const {
    id,
    customerID,
    companyID,
    userID,
    silentModeExpireDate,
    evaluationPeriod,
    dimensionGroupingOptions,
    applicationMetadata,
    ...restPolicy
  } = policy;
  // Using fallbacks for both of hte following because if either is undefined, the subsequent Math.min evaluates to NaN
  const maxKeysPerPolicy = getMaxKeysPerPolicy(restPolicy.application) || 0;
  const policyNTopKeysEvaluated = restPolicy.nTopKeysEvaluated || POLICY_DEFAULT_VALUES.nTopKeysEvaluated;

  // To prevent unsaveable policies, cap nTopKeysEvaluated with company permissions
  // See: https://github.com/kentik/ui-app/issues/25777
  restPolicy.nTopKeysEvaluated = `${Math.min(maxKeysPerPolicy, policyNTopKeysEvaluated)}`;

  // if its a toggle-mode policy, get the activate and clearance delays from the rule
  if (restPolicy?.activationSettings?.mode === 'activationModeToggle') {
    // delays are stored at the rule severity level but all levels must have the same delays
    // set the delays to whatever is on the 1st severity or set it to undefined
    restPolicy.activationSettings.activationDelay = transformToMinutes(
      restPolicy.rule?.levels?.[0]?.toggle?.activation_delay
    );
    restPolicy.activationSettings.clearanceDelay = transformToMinutes(
      restPolicy.rule?.levels?.[0]?.toggle?.clearance_delay
    );
  }

  if (dimensionGroupingOptions) {
    dimensionGroupingOptions.GroupingDimensions = dimensionGroupingOptions.GroupingDimensions || [];
    dimensionGroupingOptions.NumGroupingDimensions = dimensionGroupingOptions.GroupingDimensions.length;
  }

  const dimension_grouping_enabled =
    dimensionGroupingOptions?.NumGroupingDimensions > 1 && dimensionGroupingOptions.MaxPerGroup > 0;

  const cidrMetrics = $dictionary.get('cidrMetrics') || [];

  restPolicy.dimensions = restPolicy.dimensions.map((dimension) => {
    if (!dimension && !dimension.includes('_cidr_')) {
      return dimension;
    }

    const [stem, cidrs] = dimension.split('_cidr_');

    if (stem && cidrs && cidrMetrics.includes(stem)) {
      const [cidr, cidr6] = cidrs.split('_');
      restPolicy.cidr = cidr;
      restPolicy.cidr6 = cidr6;
      return stem;
    }

    return dimension;
  });

  // clear out default dashboard id
  if (restPolicy.dashboardID === '49') {
    delete restPolicy.dashboardID;
  }

  // Need applicationMetadata available before getting measurement model
  restPolicy.applicationMetadata = applicationMetadata !== undefined ? safelyParseJSON(applicationMetadata) : {};

  // if it's a metric policy, we have to construct a metricConfig object and transform filters into properties on this object
  if (restPolicy.application === POLICY_APPLICATIONS.METRIC) {
    const { measurement } = restPolicy.applicationMetadata;
    const measurementModel = $metrics.measurementModel(measurement);

    if (measurementModel) {
      restPolicy.filters.filterGroups = removeHiddenKmetricsFilters(restPolicy, measurementModel);
      restPolicy.selectedMeasurement = measurement;
      restPolicy.dimensions = mapKmetricDimensions(restPolicy);
    }

    // The Filters that we want to show to user, which are quite different than the filters stored with the Policy,
    // are stored in `applicationMetadata.filters`
    if (restPolicy.applicationMetadata?.filters?.filterGroups.length > 0) {
      restPolicy.filters = restPolicy.applicationMetadata.filters;
    }

    restPolicy.filters = $metrics.dimensionFilterKdeToReconTransformer(restPolicy.filters, measurement);
    const { metrics } = restPolicy;
    const metricAggFunc = restPolicy.customMetricDefinitions?.[metrics[0]]?.aggregateFunction;

    restPolicy.metricConfig = {
      selectedMeasurement: measurementModel?.id,
      selectedDimensions: restPolicy.dimensions,
      selectedMetrics: metrics,
      // also populate the defaults for now, these are just visualization configurations
      // that the user doesn't have access to anyway, fueling thresholds preview
      selectedTransformation: 'none',
      selectedAggFunc: POLICY_AGG_FUNCTIONS_DESERIALIZE[metricAggFunc] || 'avg',
      selectedRollupsAggFunc: POLICY_AGG_FUNCTIONS_DESERIALIZE[metricAggFunc] || 'avg',
      lookback_seconds: 60 * 60,
      selectedSortOrder: 'desc',
      includeTimeseries: 10,
      selectedLimitCount: 10,
      selectedWindowSize: 0,
      selectedRollupsLimit: 10,
      limit: 10,
      selectedVisualization: 'line',
      selectedVisualizationRollup: '',
      selectedVisualizationMetric: ''
      // Filters will be added here later, after being deserialized
    };
  }

  const silenced = isSilentMode(silentModeExpireDate);
  const calculatedSilentModeExpireDate = getSilentModeDate(silentModeExpireDate);

  // Only multiply by comparisonValueFactor for greek metrics
  if (GREEK_METRICS.includes(restPolicy.metrics[0])) {
    let minTrafficValueFactor = GREEK_FACTORS[0].value;

    // iterate through GREEK_FACTORS and find the factor that brings our value closest to 1000 or below
    for (const factor of GREEK_FACTORS) {
      if (restPolicy.minTrafficValue / factor.value < 1000) {
        minTrafficValueFactor = factor.value;
        break;
      }
    }

    const minTrafficNumberValue = parseInt(restPolicy.minTrafficValue);

    if (restPolicy.minTrafficValue && !Number.isNaN(minTrafficNumberValue) && minTrafficNumberValue > 0) {
      restPolicy.minTrafficValueFactor = minTrafficValueFactor;
      restPolicy.minTrafficValue = minTrafficNumberValue / minTrafficValueFactor;
    }
  }

  return {
    ...restPolicy,
    labelIds: (restPolicy.labelIds || []).map(Number),
    application: restPolicy.application || POLICY_APPLICATIONS.CORE,
    evaluationPeriod: parseInt(evaluationPeriod.replace('s', ''), 10),
    silenced,
    silentModeExpireDate: calculatedSilentModeExpireDate,
    id: parseInt(id, 10),
    user_id: userID,
    dimension_grouping_enabled,
    dimensionGroupingOptions
  };
}

function serialize(policy) {
  const {
    rule,
    customer_id,
    user_id,
    minTrafficValue,
    minTrafficValueFactor,
    daysToExpire,
    silenced,
    silentModeExpireDate,
    evaluationPeriod,
    dimension_grouping_enabled,
    applicationMetadata = {},
    ...restPolicy
  } = policy;

  let { dimensionGroupingOptions, cidr, cidr6 } = policy;

  delete restPolicy.cidr;
  delete restPolicy.cidr6;

  // protect against weird cidr values but mostly undefined
  cidr = parseInt(cidr);
  if (Number.isNaN(cidr) || cidr < 0 || cidr > 32) {
    cidr = 32;
  }
  cidr6 = parseInt(cidr6);
  if (Number.isNaN(cidr6) || cidr6 < 0 || cidr6 > 128) {
    cidr6 = 128;
  }

  const cidrSuffix = `_cidr_${cidr}_${cidr6}`;
  const defaultCidrSuffix = '_cidr_32_128';
  const cidrMetrics = $dictionary.get('cidrMetrics') || [];

  if (dimensionGroupingOptions?.NumGroupingDimensions > 0) {
    dimensionGroupingOptions.GroupingDimensions = policy.dimensions.slice(
      0,
      dimensionGroupingOptions.NumGroupingDimensions
    );
  }

  if (!dimension_grouping_enabled) {
    dimensionGroupingOptions = null;
  }

  if (restPolicy.activationSettings) {
    restPolicy.activationSettings = { ...restPolicy.activationSettings, disableLegacyFlow: true };
  }

  let minTrafficValueToUse = minTrafficValue;
  const minTrafficNumberValue = parseInt(minTrafficValue);

  if (minTrafficValue && !Number.isNaN(minTrafficNumberValue) && minTrafficNumberValue > 0 && minTrafficValueFactor) {
    minTrafficValueToUse = minTrafficNumberValue * minTrafficValueFactor;
  }

  const serializedPolicy = {
    ...restPolicy,
    minTrafficValue: `${minTrafficValueToUse}`,
    evaluationPeriod: restPolicy?.activationSettings?.mode === 'activationModeToggle' ? '0s' : `${evaluationPeriod}s`,
    dashboardID: restPolicy.dashboardID || '49',
    dimensionGroupingOptions
  };

  if (Number.isNaN(policy.id)) {
    policy.id = undefined;
  }

  // Handle any custom dimensions
  serializedPolicy.dimensions = serializedPolicy.dimensions.map((dimension) => {
    const isCidrDimension = cidrMetrics.includes(dimension);
    return isCidrDimension && cidrSuffix !== defaultCidrSuffix ? dimension + cidrSuffix : dimension;
  });

  // ... just in case we missed a policy somehow during migrations, absolute safety measure
  if (serializedPolicy.application === undefined) {
    serializedPolicy.application = POLICY_APPLICATIONS.CORE;
  }

  if (daysToExpire > 0) {
    serializedPolicy.expireDate = getPolicyExpireDate(daysToExpire);
  }

  // If not silenced, backdate it to simulate expiration. Otherwise, API defaults to +7 days.
  serializedPolicy.silentModeExpireDate = silenced ? silentModeExpireDate : '2020-01-01T00:00:01Z';

  // If it's a metric policy (Up/Down or Thresholds), we have to eliminate the metricConfig object, and transform a lot of
  // data into filters
  if (serializedPolicy.application === POLICY_APPLICATIONS.METRIC) {
    const { metrics, dimensions } = serializedPolicy;
    const { measurement, fullMeasurement } = getMeasurementModelDataFromPolicy(policy);
    const measurementName = fullMeasurement?.measurement;
    if (String(measurementName).length && measurement !== applicationMetadata?.measurement) {
      applicationMetadata.measurement = measurementName;
    }

    const filterGroupsToSerialize = [];
    const customMetricDefinitions = {};

    metrics.forEach((metric) => {
      const matchingMetric = fullMeasurement.storage.Metrics[metric];
      const isToggleMode = serializedPolicy.activationSettings.mode === POLICY_MODES.TOGGLE;

      if (matchingMetric) {
        customMetricDefinitions[metric] = {
          attribute: $metrics.getAppProtocolDimension(measurementName, matchingMetric.Column),
          aggregateFunction:
            POLICY_AGG_FUNCTIONS[isToggleMode ? 'last' : serializedPolicy.metricConfig.selectedRollupsAggFunc]
        };
      }
    });

    if (applicationMetadata?.interfaces) {
      const interfaceFilters = convertInterfacesToFilterGroup(applicationMetadata.interfaces, fullMeasurement);

      if (interfaceFilters.length > 0) {
        filterGroupsToSerialize.push(...interfaceFilters);
      }
    }

    // add dimensions, metrics with custom mappings
    serializedPolicy.dimensions = dimensions
      .map((dimension) => {
        const matchingDimension = fullMeasurement.storage.Dimensions[dimension];
        return matchingDimension && $metrics.getAppProtocolDimension(measurementName, matchingDimension.Column);
      })
      .concat([
        $metrics.getAppProtocolDimension(measurementName, 'km_measurement_name'),
        $metrics.getAppProtocolDimension(measurementName, 'km_device_id')
      ])
      .filter(Boolean);
    serializedPolicy.customMetricDefinitions = customMetricDefinitions;

    // takes all of the assembled Filters and transforms them into the format the API expects
    serializedPolicy.filters.filterGroups = $metrics.dimensionFilterReconToKdeTransformer(
      filterGroupsToSerialize.concat(restPolicy.filters.filterGroups),
      measurement
    );

    // add the measurement as a filter group
    const measurementAsFilterGroup = getKmetricMeasurementFilterGroup(measurement);
    serializedPolicy.filters.filterGroups.push(measurementAsFilterGroup);
  }

  // have to stringify applicationMetadata
  if (Object.keys(applicationMetadata).length > 0) {
    serializedPolicy.applicationMetadata = JSON.stringify(applicationMetadata);
  }

  // remove the metricConfig object that's meant to be used on the UI only
  delete serializedPolicy.metricConfig;

  // also remove selectedMeasurement, we use that field on UI exclusively
  delete serializedPolicy.selectedMeasurement;

  // activationSettings.activationDelay and clearanceDelay will get cleaned up in node

  return serializedPolicy;
}

export default {
  serialize,
  deserialize
};
