import React from 'react';
import { action, computed, observable } from 'mobx';
import moment from 'moment';

import $dictionary from 'app/stores/$dictionary';

import Flex from 'core/components/Flex';

import api from 'core/util/api';
import Collection from 'core/model/Collection';
import CostItemCollection from 'app/stores/cost/CostItemCollection';
import CostItemModel from './CostItemModel';

import CostHistoryCollection from './CostHistoryCollection';
import CostSummariesCollection from './CostSummariesCollection';
import CostSnapshotsCollection from './CostSnapshotsCollection';
import CurrencyCollection from './CurrencyCollection';
import ProviderCollection from './ProviderCollection';

class CostStore {
  costHistory = new CostHistoryCollection();

  costSummaries = new CostSummariesCollection();

  costSnapshots = new CostSnapshotsCollection();

  currencies = new CurrencyCollection();

  providers = new ProviderCollection();

  interfaceMap = {};

  initialize() {
    api.get('/api/ui/cost/currencyRates').then((currencyRates) => {
      this.currencyRates = currencyRates;
    });
  }

  @computed
  get costProvidersOptions() {
    return this.getCostOptions('providers');
  }

  @computed
  get costConnTypesOptions() {
    return this.getCostOptions('connTypes', $dictionary.dictionary?.interfaceClassification?.connectivityTypes);
  }

  @computed
  get costSitesOptions() {
    return this.getCostOptions('sites');
  }

  @computed
  get costSiteMarketsOptions() {
    return this.getCostOptions('siteMarkets');
  }

  getCostOptions(group, labelTranslatorMap) {
    const costs = this.costSummaries.models
      .filter((summary) => summary.get('month') === moment().set('date', 1).format('YYYY-MM-DD'))[0]
      ?.get(group);
    return (
      costs &&
      Object.values(costs).map((cost) => ({
        value: cost.id,
        label: (
          <Flex justifyContent="space-between" alignItems="center" flex={1}>
            {labelTranslatorMap && labelTranslatorMap[cost.name] ? labelTranslatorMap[cost.name] : cost.name}
          </Flex>
        ),
        filterLabel: cost.name
      }))
    );
  }

  fetchHistory(options) {
    const { summaryOnly = false, previousMonthHistoryOnly = false } = options || {};
    const query = {};
    if (summaryOnly) {
      query.summaryOnly = true;
      query.start_date = moment().subtract(1, 'months').startOf('month').subtract(1, 'days').toISOString();
    }
    if (previousMonthHistoryOnly) {
      query.previousMonthHistoryOnly = true;
    }
    const fetchOptions = { query };
    return Promise.all([
      this.store.$exports.getSettings(),
      this.currencies.fetch(),
      this.providers.fetch(),
      this.costHistory.fetch(fetchOptions)
    ]).then(([{ currentMonth }]) => {
      if (currentMonth) {
        const month = moment(currentMonth, 'YYYY-MM-DD');
        if (month.isValid()) {
          this._currentMonth = month;
        }
      }

      this.setHistory();
    });
  }

  setHistory = action(() => {
    // Set costHistory
    this.costHistory.populateCostGroups(this.providers);
    // Set summaries
    this.costSummaries.set(this.costHistory.summaries);
    this.costSummaries.lastFetched = Date.now();
    this.costSummaries.hasFetched = true;
  });

  get baseUrl() {
    return this.store.$app.isExport ? '/v4/export/edge/costs' : '/v4/edge/costs';
  }

  setCompanyCurrency(currency) {
    this.store.$companySettings.setCompanyCurrency(currency).then(() => this.fetchHistory());
  }

  // Private - do not use directly
  @observable
  _currentMonth = moment();

  get currentMonth() {
    return moment(this._currentMonth);
  }

  @action
  setCurrentMonth(month) {
    this._currentMonth = month ? moment(month) : moment();
    this.store.$exports.setHash({ currentMonth: month });
  }

  @computed
  get currentSummaryMonth() {
    return this.currentMonth.startOf('month').format('YYYY-MM-DD');
  }

  @computed
  get previousSummaryMonth() {
    return moment(this.currentMonth).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
  }

  getSuggestedProviders() {
    return api.get('/api/ui/cost/suggestedProviders');
  }

  getProvider(id) {
    return this.providers.get(id);
  }

  getDeviceInterfaces(interfaces) {
    const data = interfaces.map((i) => this.parseInterfaceIdentifier(i));
    return api.post('/api/ui/lookups/deviceInterfaces', { data });
  }

  parseInterfaceIdentifier(intf) {
    const { device_id, snmp_id } = intf;
    return { device_id, snmp_id };
  }

  getNextBillingDate(billingDate) {
    const now = moment();
    const today = now.get('date');

    const nextBillingDate =
      billingDate >= today ? moment().date(billingDate) : moment().add(1, 'month').date(billingDate);

    return nextBillingDate;
  }

  getConnectivityLabel = (type) =>
    this.store.$dictionary.get('interfaceClassification').connectivityTypes[type] || type;

  @computed
  get providersByBillingDate() {
    const results = {};

    this.providers.each((provider) => {
      const providerId = provider.get('id');
      const providerSettings = provider.get('settings') || {};
      const costs = this.getCosts('providers', providerId);
      if (costs) {
        const costHistoriesMap = costs.reduce((map, costHistory) => {
          map[costHistory.get('cost_group_id')] = costHistory;
          return map;
        }, {});

        provider.costGroups.each((costGroup) => {
          const costGroupId = costGroup.get('id');
          const settings = costGroup.get('settings') || {};
          const billingDate = settings.billingDate || providerSettings.billingDate;
          const costHistory = costHistoriesMap[costGroupId];

          if (billingDate && costHistory) {
            results[billingDate] = results[billingDate] || {};
            results[billingDate][providerId] = results[billingDate][providerId] || {
              costHistories: [],
              provider,
              billingDate: this.getNextBillingDate(billingDate)
            };
            results[billingDate][providerId].costHistories.push(costHistory);
          }
        });
      }
    });

    return results;
  }

  @computed
  get contractsByMonthDay() {
    const results = {};

    this.providers.each((provider) => {
      const providerId = provider.get('id');
      const providerSettings = provider.get('settings') || {};
      const costHistoriesMap = this.getCosts('providers', providerId).reduce((map, costHistory) => {
        map[costHistory.get('cost_group_id')] = costHistory;
        return map;
      }, {});

      provider.costGroups.each((costGroup) => {
        const costGroupId = costGroup.get('id');
        const settings = costGroup.get('settings') || {};
        const contractDate = settings.contractDate || providerSettings.contractDate;
        const costHistory = costHistoriesMap[costGroupId];

        if (contractDate && costHistory) {
          const monthDay = moment(contractDate).format('YYYY-MM-DD');
          results[monthDay] = results[monthDay] || {};
          results[monthDay][providerId] = results[monthDay][providerId] || {
            costHistories: [],
            provider,
            contractDate: moment(contractDate)
          };
          results[monthDay][providerId].costHistories.push(costHistory);
        }
      });
    });

    return results;
  }

  @computed
  get providersDueToday() {
    return this.providersByBillingDate[moment().get('date')] || {};
  }

  invoiceDueDays = 7;

  contractDueDays = 14;

  @computed
  get billsDueSoon() {
    const now = moment();
    const futureDate = moment().add(this.invoiceDueDays, 'days');

    const sortedDates = Object.keys(this.providersByBillingDate)
      .filter((billingDate) => this.getNextBillingDate(billingDate).isBetween(now, futureDate, 'day', '[]'))
      .sort((a, b) => moment(this.getNextBillingDate(a)).diff(moment(this.getNextBillingDate(b))));

    return sortedDates.map((billingDate) => ({
      billingDate: this.getNextBillingDate(billingDate),
      providers: Object.values(this.providersByBillingDate[billingDate])
    }));
  }

  @computed
  get contractsDueSoon() {
    const now = moment();
    const futureDate = moment().add(this.contractDueDays, 'days');

    const sortedDates = Object.keys(this.contractsByMonthDay)
      .filter((monthDay) => moment(monthDay).isBetween(now, futureDate, 'day', '[]'))
      .sort((a, b) => moment(a).diff(moment(b)));

    return sortedDates.map((monthDay) => ({
      contractDate: moment(monthDay),
      providers: Object.values(this.contractsByMonthDay[monthDay])
    }));
  }

  @computed
  get currencyOptions() {
    return this.currencies.models.map((model) => ({
      value: model.get('name'),
      label: `${model.get('symbol')} ${model.get('description')}`,
      symbol: model.get('symbol'),
      description: model.get('description')
    }));
  }

  getCostPerMbit({ traffic, ingress, egress, cost }) {
    let trafficMbps = traffic || Math.max(ingress, egress);
    trafficMbps /= 10 ** 6;
    // when traffic is <1Mbps, we consider the cost/Mbps that of 1Mbps
    trafficMbps = Math.max(trafficMbps, 1);

    let costPerMbit = trafficMbps ? cost / trafficMbps : cost;
    costPerMbit = (costPerMbit * 100) / 100;

    return Number.isNaN(costPerMbit) ? 0 : costPerMbit;
  }

  // Returns an array of CostHistory models for the given month, entity (provider, site, connType, siteMarket) and id
  getCostsByMonth(month, summaryType /* providers, connTypes, sites, siteMarkets */, id) {
    const summaryModel = this.costSummaries.models.find((model) => model.get('month') === month);
    const summaries = summaryModel ? summaryModel.get(summaryType) : {};
    const summary = summaries[id];
    let result = summary ? summary.costHistoryIds.map((costHistoryId) => this.costHistory.get(costHistoryId)) : [];

    if (summaryType === 'siteMarkets') {
      const siteIdsSet = new Set(summary?.siteIds);
      result = result.map((model) => {
        const metadata = model.get('metadata');
        const sites = metadata?.results?.sites || {};
        metadata.results.sites = Object.values(sites)
          .filter((site) => siteIdsSet.has(site.id))
          .reduce((results, site) => {
            results[site.id] = site;
            return results;
          }, {});
        model.set('metadata', metadata);
        return model;
      });
    }

    return result.filter(Boolean);
  }

  // Returns an array of CostHistory models that are due in the future
  getCosts(summaryType /* providers, connTypes, sites, siteMarkets */, id) {
    let costs = [];

    // check the next billing cycle
    let summaryMonth = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');

    costs = this.getCostsByMonth(summaryMonth, summaryType, id);

    if (costs.length === 0) {
      summaryMonth = this.currentSummaryMonth;
      costs = this.getCostsByMonth(summaryMonth, summaryType, id);
    }

    return costs;
  }

  getCostGroupForInterface = ({ snmp_id: snmpId, device_id: deviceId }) => {
    let result = false;

    this.providers.some((model) =>
      model.costGroups.some((cg) => {
        const match = cg.interfaces.some(({ device_id, snmp_id }) => +deviceId === device_id && +snmpId === snmp_id);

        if (match) {
          result = cg;
        }

        return match;
      })
    );

    return result;
  };

  @computed
  get sparklineData() {
    const data = {
      cost: [],
      costEdge: [],
      costPerMbitEdge: [],
      costBackbone: [],
      egress: [],
      ingress: [],
      costPerMbit: []
    };

    this.costSummaries.each((model) => {
      if (model.get('month') <= this.currentSummaryMonth) {
        data.cost.push(model.get('cost'));
        data.costEdge.push(model.get('edge').cost);
        data.costPerMbitEdge.push(this.getCostPerMbit({ ...model.get('edge') }));
        data.costBackbone.push(model.get('backbone').cost);
        data.ingress.push(model.get('ingress'));
        data.egress.push(model.get('egress'));
        data.costPerMbit.push(this.getCostPerMbit({ ...model.get() }));
      }
    });

    return data;
  }

  @computed
  get summaryMonths() {
    return this.costSummaries.models
      .filter((model) => model.get('month') <= this.currentSummaryMonth)
      .map((model) => moment(model.get('month')).format('MMM YYYY'));
  }

  // Returns series data for charts given a type (providers, connTypes, sites, siteMarkets) and a collection of cost summaries
  getSummarySeries(summaryType, summaries = this.costSummaries) {
    const costSeries = {};
    const costPerMbitSeries = {};
    const ingressSeries = {};
    const egressSeries = {};
    const trafficSeries = {};
    const costEdgeSeries = {};
    const costPerMbitEdgeSeries = {};
    const ingressEdgeSeries = {};
    const egressEdgeSeries = {};
    const trafficEdgeSeries = {};
    const costBackboneSeries = {};
    const costPerMbitBackboneSeries = {};
    const ingressBackboneSeries = {};
    const egressBackboneSeries = {};
    const trafficBackboneSeries = {};

    const emptyData = this.summaryMonths.map(() => ({ y: 0 }));
    let { currency } = this.store.$companySettings;

    const now = moment();
    const today = now.get('date');

    summaries.each((model, idx) => {
      const rows = model.get(summaryType); // providers, connTypes, sites, siteMarkets
      const modelMoment = moment(model.get('month'));
      currency = model.get('currency');

      if (model.get('month') <= this.currentSummaryMonth) {
        Object.keys(rows).forEach((key) => {
          const row = rows[key];
          let { name } = row;
          let hatched = false;

          if (summaryType === 'connTypes') {
            name = this.getConnectivityLabel(row.name);
          }

          if (!name) {
            name = '';
          }

          if (summaryType === 'costGroups') {
            const { costGroup } = new CostItemModel(row);
            const { billingDate } = costGroup;
            hatched =
              parseInt(billingDate) &&
              (parseInt(billingDate) === 1 || today <= parseInt(billingDate)) &&
              now.isSame(modelMoment, 'month') &&
              now.isSame(modelMoment, 'year');
          }

          costSeries[key] = costSeries[key] || { name, key, data: [...emptyData] };
          costSeries[key].name = name;
          costSeries[key].data[idx] = { y: row.cost, hatched };

          costPerMbitSeries[key] = costPerMbitSeries[key] || { name, key, data: [...emptyData] };
          costPerMbitSeries[key].name = name;
          costPerMbitSeries[key].data[idx] = { y: this.getCostPerMbit(row), hatched };

          ingressSeries[key] = ingressSeries[key] || {
            name,
            key,
            meteredPercentile: row.meteredPercentile,
            data: [...emptyData]
          };
          ingressSeries[key].name = name;
          ingressSeries[key].data[idx] = { y: row.ingress, hatched };

          egressSeries[key] = egressSeries[key] || {
            name,
            key,
            meteredPercentile: row.meteredPercentile,
            data: [...emptyData]
          };
          egressSeries[key].name = name;
          egressSeries[key].data[idx] = { y: row.egress, hatched };

          trafficSeries[key] = trafficSeries[key] || {
            name,
            key,
            meteredPercentile: row.meteredPercentile,
            data: [...emptyData]
          };
          trafficSeries[key].name = name;
          trafficSeries[key].data[idx] = { y: row.traffic, hatched };

          if (row.edge && (row.edge.cost || row.edge.ingress || row.edge.egress || row.edge.traffic)) {
            costEdgeSeries[key] = costEdgeSeries[key] || { name, key, data: [...emptyData] };
            costEdgeSeries[key].name = name;
            costEdgeSeries[key].data[idx] = { y: row.edge.cost, hatched };
            costPerMbitEdgeSeries[key] = costPerMbitEdgeSeries[key] || { name, key, data: [...emptyData] };
            costPerMbitEdgeSeries[key].name = name;
            costPerMbitEdgeSeries[key].data[idx] = { y: this.getCostPerMbit(row.edge), hatched };
            ingressEdgeSeries[key] = ingressEdgeSeries[key] || {
              name,
              key,
              data: [...emptyData],
              meteredPercentile: row.meteredPercentile
            };
            ingressEdgeSeries[key].name = name;
            ingressEdgeSeries[key].data[idx] = { y: row.edge.ingress, hatched };
            egressEdgeSeries[key] = egressEdgeSeries[key] || {
              name,
              key,
              data: [...emptyData],
              meteredPercentile: row.meteredPercentile
            };
            egressEdgeSeries[key].name = name;
            egressEdgeSeries[key].data[idx] = { y: row.edge.egress, hatched };
            trafficEdgeSeries[key] = trafficEdgeSeries[key] || {
              name,
              key,
              data: [...emptyData],
              meteredPercentile: row.meteredPercentile
            };
            trafficEdgeSeries[key].name = name;
            trafficEdgeSeries[key].data[idx] = { y: row.edge.traffic, hatched };
          }

          if (
            row.backbone &&
            (row.backbone.cost || row.backbone.ingress || row.backbone.egress || row.backbone.traffic)
          ) {
            costBackboneSeries[key] = costBackboneSeries[key] || { name, key, data: [...emptyData] };
            costBackboneSeries[key].name = name;
            costBackboneSeries[key].data[idx] = { y: row.backbone.cost, hatched };
            costPerMbitBackboneSeries[key] = costPerMbitBackboneSeries[key] || { name, key, data: [...emptyData] };
            costPerMbitBackboneSeries[key].name = name;
            costPerMbitBackboneSeries[key].data[idx] = { y: this.getCostPerMbit(row.backbone), hatched };
            ingressBackboneSeries[key] = ingressBackboneSeries[key] || {
              name,
              key,
              data: [...emptyData],
              meteredPercentile: row.meteredPercentile
            };
            ingressBackboneSeries[key].name = name;
            ingressBackboneSeries[key].data[idx] = { y: row.backbone.ingress, hatched };
            egressBackboneSeries[key] = egressBackboneSeries[key] || {
              name,
              key,
              data: [...emptyData],
              meteredPercentile: row.meteredPercentile
            };
            egressBackboneSeries[key].name = name;
            egressBackboneSeries[key].data[idx] = { y: row.backbone.egress, hatched };
            trafficBackboneSeries[key] = trafficBackboneSeries[key] || {
              name,
              key,
              data: [...emptyData],
              meteredPercentile: row.meteredPercentile
            };
            trafficBackboneSeries[key].name = name;
            trafficBackboneSeries[key].data[idx] = { y: row.backbone.traffic, hatched };
          }
        });
      }
    });

    return {
      currency,
      cost: Object.values(costSeries).sort((a, b) => a.name.localeCompare(b.name)),
      costPerMbit: Object.values(costPerMbitSeries).sort((a, b) => a.name.localeCompare(b.name)),
      ingress: Object.values(ingressSeries).sort((a, b) => a.name.localeCompare(b.name)),
      egress: Object.values(egressSeries).sort((a, b) => a.name.localeCompare(b.name)),
      traffic: Object.values(trafficSeries).sort((a, b) => a.name.localeCompare(b.name)),
      costEdge: Object.values(costEdgeSeries).sort((a, b) => a.name.localeCompare(b.name)),
      costPerMbitEdge: Object.values(costPerMbitEdgeSeries).sort((a, b) => a.name.localeCompare(b.name)),
      ingressEdge: Object.values(ingressEdgeSeries).sort((a, b) => a.name.localeCompare(b.name)),
      egressEdge: Object.values(egressEdgeSeries).sort((a, b) => a.name.localeCompare(b.name)),
      trafficEdge: Object.values(trafficEdgeSeries).sort((a, b) => a.name.localeCompare(b.name)),
      costBackbone: Object.values(costBackboneSeries).sort((a, b) => a.name.localeCompare(b.name)),
      costPerMbitBackbone: Object.values(costPerMbitBackboneSeries).sort((a, b) => a.name.localeCompare(b.name)),
      ingressBackbone: Object.values(ingressBackboneSeries).sort((a, b) => a.name.localeCompare(b.name)),
      egressBackbone: Object.values(egressBackboneSeries).sort((a, b) => a.name.localeCompare(b.name)),
      trafficBackbone: Object.values(trafficBackboneSeries).sort((a, b) => a.name.localeCompare(b.name))
    };
  }

  get summaryProviderSeries() {
    return this.getSummarySeries('providers');
  }

  get summaryConnTypeSeries() {
    return this.getSummarySeries('connTypes');
  }

  get summarySiteSeries() {
    return this.getSummarySeries('sites');
  }

  get summarySiteMarketSeries() {
    return this.getSummarySeries('siteMarkets');
  }

  get summarySeries() {
    return {
      connTypes: this.summaryConnTypeSeries,
      providers: this.summaryProviderSeries,
      sites: this.summarySiteSeries,
      siteMarkets: this.summarySiteMarketSeries
    };
  }

  combineSeries = (allSeries) =>
    allSeries
      .map(({ key, name, series }) => ({
        key,
        name,
        data: series.reduce((acc, { data = [] }) => {
          data.forEach((point, idx) => {
            acc[idx] = acc[idx] || { y: 0 };
            acc[idx].y += point.y;
            acc[idx].hatched = acc[idx].hatched || point.hatched;
          });
          return acc;
        }, [])
      }))
      .filter(({ data }) => data.length > 0);

  buildSummary = ({ summary, key, name, model, id, summaryType, connType, rollUpBy, siteIds }) => {
    const attributes = model.get ? model.get() : model;
    siteIds = siteIds ? new Set(siteIds) : undefined;

    summary = summary || {
      id: key,
      name,
      cost: 0,
      egress: 0,
      ingress: 0,
      traffic: 0,
      trafficDirection: '',
      costHistoryIds: [],
      edge: {
        cost: 0,
        egress: 0,
        ingress: 0,
        traffic: 0
      },
      backbone: {
        cost: 0,
        egress: 0,
        ingress: 0,
        traffic: 0
      }
    };

    summary.costHistoryIds.push(attributes.id);
    summary.currency = attributes.currency;
    summary.meteredPercentile = attributes?.metadata?.results?.meteredPercentile;

    // If we are building summaries for a site then get our cost and traffic numbers from the costHistory interfaces
    if (summaryType === 'sites' || rollUpBy === 'siteMarkets') {
      const siteInterfaces = attributes?.metadata?.results?.interfaces || {};
      const trafficDirection = attributes?.metadata?.results?.trafficDirection || '';

      Object.values(siteInterfaces).forEach((intf) => {
        if (
          (summaryType === 'sites' && intf.site_id === id) ||
          (rollUpBy === 'siteMarkets' && siteIds.has(intf.site_id))
        ) {
          summary.cost += intf.cost;
          summary.egress += intf.egress;
          summary.ingress += intf.ingress;

          if (trafficDirection === 'ingress') {
            summary.traffic += intf.ingress;
          } else if (trafficDirection === 'egress') {
            summary.traffic += intf.egress;
          } else {
            summary.traffic += Math.max(intf.ingress, intf.egress);
          }

          summary.trafficDirection =
            trafficDirection === 'mixed' || summary.trafficDirection !== trafficDirection ? 'mixed' : trafficDirection;

          if (connType === 'backbone') {
            summary.backbone.cost += intf.cost;
            summary.backbone.egress += intf.egress;
            summary.backbone.ingress += intf.ingress;
            summary.backbone.traffic += Math.max(intf.ingress, intf.egress);
          } else {
            summary.edge.cost += intf.cost;
            summary.edge.egress += intf.egress;
            summary.edge.ingress += intf.ingress;
            summary.edge.traffic += Math.max(intf.ingress, intf.egress);
          }
        }
      });
      // otherwise, use the rolled up numbers form the costHistory model
    } else if (!siteIds || siteIds?.has(id)) {
      summary.cost += attributes.cost;
      summary.egress += attributes.egress;
      summary.ingress += attributes.ingress;
      summary.traffic += attributes.traffic;
      summary.trafficDirection =
        attributes.trafficDirection === 'mixed' || summary.trafficDirection !== attributes.trafficDirection
          ? 'mixed'
          : attributes.trafficDirection;

      if (connType === 'backbone') {
        summary.backbone.cost += attributes.cost;
        summary.backbone.egress += attributes.egress;
        summary.backbone.ingress += attributes.ingress;
        summary.backbone.traffic += attributes.traffic;
      } else {
        summary.edge.cost += attributes.cost;
        summary.edge.egress += attributes.egress;
        summary.edge.ingress += attributes.ingress;
        summary.edge.traffic += attributes.traffic;
      }
    }

    return summary;
  };

  // Returns rolled-up series data for charts given a type (providers, connTypes, sites, siteMarkets), a subType and an id of the type
  getSummarySeriesRollup = ({ id, summaryType, rollUpBy = 'costGroups' }) => {
    const summaries = this.costSummaries.models.map((model) => {
      const { currency, month, siteMarkets = {} } = model.get();
      const rollups = {};
      let ingress = 0;
      let egress = 0;
      let traffic = 0;
      let trafficDirection;
      let cost = 0;
      const edge = {
        ingress: 0,
        egress: 0,
        traffic: 0,
        cost: 0
      };
      const backbone = {
        ingress: 0,
        egress: 0,
        traffic: 0,
        cost: 0
      };

      this.getCostsByMonth(month, summaryType, id).forEach((costHistoryModel) => {
        const connType = costHistoryModel.costGroupCurrent?.get('type');
        let key;
        let name;
        ingress += costHistoryModel.get('ingress');
        egress += costHistoryModel.get('egress');
        traffic += costHistoryModel.get('traffic') || 0;
        cost += costHistoryModel.get('cost');
        if (connType === 'backbone') {
          backbone.ingress += costHistoryModel.get('ingress');
          backbone.egress += costHistoryModel.get('egress');
          backbone.traffic += costHistoryModel.get('traffic') || 0;
          backbone.cost += costHistoryModel.get('cost');
        } else {
          edge.ingress += costHistoryModel.get('ingress');
          edge.egress += costHistoryModel.get('egress');
          edge.traffic += costHistoryModel.get('traffic') || 0;
          edge.cost += costHistoryModel.get('cost');
        }

        if (trafficDirection === 'mixed' || costHistoryModel.get('trafficDirection') !== trafficDirection) {
          trafficDirection = 'mixed';
        }

        if (rollUpBy === 'costGroups') {
          key = costHistoryModel.costGroup.get('id');
          name = costHistoryModel.costGroup.get('name');

          if (summaryType === 'sites' || summaryType === 'connTypes') {
            name = `${costHistoryModel.costGroup.get('provider').name} - ${name}`;
          }
          rollups[key] = this.buildSummary({
            summary: rollups[key],
            key,
            name,
            model: costHistoryModel,
            id,
            summaryType,
            connType,
            rollUpBy
          });
        }

        if (rollUpBy === 'providers') {
          key = costHistoryModel.costGroup.get('provider_id');
          name = costHistoryModel.costGroup.get('provider').name;
          rollups[key] = this.buildSummary({
            summary: rollups[key],
            key,
            name,
            model: costHistoryModel,
            id,
            summaryType,
            connType,
            rollUpBy
          });
          rollups[key].type = costHistoryModel.costGroup.get('provider').type;
        }

        if (rollUpBy === 'connTypes') {
          key = connType;
          name = this.getConnectivityLabel(key);
          rollups[key] = this.buildSummary({
            summary: rollups[key],
            key,
            name,
            model: costHistoryModel,
            id,
            summaryType,
            connType,
            rollUpBy
          });
        }

        if (rollUpBy === 'sites') {
          costHistoryModel.sites.forEach((site) => {
            rollups[site.id] = this.buildSummary({
              summary: rollups[site.id],
              key: site.id,
              id: site.id,
              name: site.title,
              model: { ...site, id: costHistoryModel.get('id') },
              summaryType,
              connType,
              rollUpBy,
              siteIds: siteMarkets[id]?.siteIds
            });
          });
        }

        if (rollUpBy === 'siteMarkets') {
          Object.values(siteMarkets).forEach((siteMarket) => {
            if (siteMarket.costHistoryIds.includes(costHistoryModel.get('id'))) {
              rollups[siteMarket.id] = this.buildSummary({
                summary: rollups[siteMarket.id],
                key: siteMarket.id,
                id: siteMarket.id,
                name: siteMarket.name,
                model: costHistoryModel,
                summaryType,
                connType,
                rollUpBy,
                siteIds: siteMarket.siteIds
              });
            }
          });
        }
      });

      return {
        month,
        currency,
        ingress,
        egress,
        traffic,
        trafficDirection,
        cost,
        edge,
        backbone,
        [rollUpBy]: rollups
      };
    });

    const summariesCollection = new Collection(summaries);
    const currSummary = this.findSummaryModel(summariesCollection, this.currentSummaryMonth);
    const prevSummary = this.findSummaryModel(summariesCollection, this.previousSummaryMonth);

    return {
      currentMonthSummary: this.getMonthSummary(currSummary.get(), prevSummary ? prevSummary.get() : {}),
      series: this.getSummarySeries(rollUpBy, summariesCollection)
    };
  };

  findSummaryModel = (summaries, month) => summaries.models.find((model) => model.get('month') === month);

  @computed
  get currentMonthSummaryModel() {
    return this.findSummaryModel(this.costSummaries, this.currentSummaryMonth);
  }

  @computed
  get previousMonthSummaryModel() {
    return this.findSummaryModel(this.costSummaries, this.previousSummaryMonth);
  }

  createSummaryCollection = (models) =>
    new CostItemCollection(
      Object.values(models).map((values) => ({
        ...values,
        costPerMbps: this.getCostPerMbit(values),
        edge: values.edge ? { ...values.edge, costPerMbps: this.getCostPerMbit(values.edge) } : undefined,
        backbone: values.backbone
          ? { ...values.backbone, costPerMbps: this.getCostPerMbit(values.backbone) }
          : undefined
      })),
      { sortState: { field: 'cost', direction: 'desc' } }
    );

  getMonthSummary(currSummary, prevSummary) {
    const { connTypes, providers, sites, siteMarkets, costGroups } = currSummary;

    return {
      ...currSummary,
      costPerMbit: this.getCostPerMbit({ ...currSummary }),

      backbone: { ...currSummary.backbone, costPerMbit: this.getCostPerMbit({ ...currSummary.backbone }) },
      edge: { ...currSummary.edge, costPerMbit: this.getCostPerMbit({ ...currSummary.edge }) },

      connTypes: connTypes ? this.createSummaryCollection(connTypes) : undefined,
      providers: providers ? this.createSummaryCollection(providers) : undefined,
      sites: sites ? this.createSummaryCollection(sites) : undefined,
      costGroups: costGroups ? this.createSummaryCollection(costGroups) : undefined,
      siteMarkets: siteMarkets ? this.createSummaryCollection(siteMarkets) : undefined,

      previous: {
        ...prevSummary,
        costPerMbit: this.getCostPerMbit({ ...prevSummary })
      }
    };
  }

  getActiveTrafficCollection = (collection, attrMap) => {
    const filtered = collection.filter(
      (model) => {
        const cost = model.get(attrMap.cost || 'cost');
        const traffic = model.get(attrMap.traffic || 'traffic');
        return cost > 0 || traffic > 0;
      },
      { immutable: true }
    );
    return new CostItemCollection(filtered);
  };

  get canEditCosts() {
    const { $auth } = this.store;
    const userRbacPermissions = $auth.getActiveUserProperty('rbacPermissions') || [];
    return userRbacPermissions.includes('connectivity_costs::update');
  }

  get canViewCosts() {
    const { $auth } = this.store;
    const userRbacPermissions = $auth.getActiveUserProperty('rbacPermissions') || [];
    return userRbacPermissions.includes('connectivity_costs::read');
  }

  @computed
  get currentMonthSummary() {
    const emptySummary = {
      cost: 0,
      edge: {},
      backbone: {},
      ingress: 0,
      egress: 0,
      currency: this.store.$companySettings.currency,
      connTypes: {},
      providers: {},
      sites: {},
      siteMarkets: {}
    };

    const currSummary = this.currentMonthSummaryModel
      ? this.currentMonthSummaryModel.get()
      : { month: this.currentSummaryMonth, ...emptySummary };
    const prevSummary = this.previousMonthSummaryModel
      ? this.previousMonthSummaryModel.get()
      : { month: this.previousSummaryMonth, ...emptySummary };

    return this.getMonthSummary(currSummary, prevSummary);
  }

  @computed
  get currentMonthRankings() {
    const { providers, connTypes, sites, siteMarkets } = this.currentMonthSummaryModel.get();

    // sorters high to low
    const sortByCost = (a, b) => b.cost - a.cost;
    const sortByCostPerMbps = (a, b) => this.getCostPerMbit({ ...b }) - this.getCostPerMbit({ ...a });
    const sortByVolume = (a, b) => Math.max(b.ingress, b.egress) - Math.max(a.ingress, a.egress);
    const sortByNumProviders = (a, b) => b.providerIds.length - a.providerIds.length;
    const sortByNumCostGroups = (a, b) => b.costGroupIds.length - a.costGroupIds.length;

    const result = {
      providersByType: {},
      providers: {
        cost: Object.values(providers).sort(sortByCost),
        costPerMbps: Object.values(providers).sort(sortByCostPerMbps),
        volume: Object.values(providers).sort(sortByVolume)
      },
      connTypes: {
        cost: Object.values(connTypes).sort(sortByCost),
        costPerMbps: Object.values(connTypes)
          .filter((attrs) => attrs.id !== 'backbone')
          .sort(sortByCostPerMbps),
        volume: Object.values(connTypes)
          .filter((attrs) => attrs.id !== 'backbone')
          .sort(sortByVolume),
        numProviders: Object.values(connTypes).sort(sortByNumProviders),
        numCostGroups: Object.values(connTypes).sort(sortByNumCostGroups)
      },
      sites: {
        cost: Object.values(sites).sort(sortByCost),
        costPerMbps: Object.values(sites).sort(sortByCostPerMbps),
        volume: Object.values(sites).sort(sortByVolume)
      },
      siteMarkets: {
        cost: Object.values(siteMarkets).sort(sortByCost),
        costPerMbps: Object.values(siteMarkets).sort(sortByCostPerMbps),
        volume: Object.values(siteMarkets).sort(sortByVolume)
      }
    };

    Object.keys(connTypes).forEach((type) => {
      result.providersByType[type] = {
        type,
        cost: Object.values(providers)
          .filter((provider) => provider.type === type)
          .sort(sortByCost),
        costPerMbps: Object.values(providers)
          .filter((provider) => provider.type === type)
          .sort(sortByCostPerMbps),
        volume: Object.values(providers)
          .filter((provider) => provider.type === type)
          .sort(sortByVolume)
      };
    });

    return result;
  }

  getCurrentMonthByEntity = (entityType /* providers, connTypes, sites, siteMarkets */, id) => {
    const summary = this.currentMonthSummary;
    const previousModel = summary.previous[entityType][id] || {};
    const model = summary[entityType].get(id);

    if (!model) {
      return false;
    }

    const {
      traffic = 0,
      ingress = 0,
      egress = 0,
      cost = 0,
      trafficCost = 0,
      costHistoryIds = [],
      edge = {},
      backbone = {}
    } = model.get();

    const entityMinimumSpend = costHistoryIds.reduce((acc, costHistoryId) => {
      const costHistory = this.costHistory.get(costHistoryId);
      const { minimumSpend = 0 } = costHistory?.costGroup || {};

      if (entityType === 'site') {
        return 0;
      }

      return acc + minimumSpend;
    }, 0);

    return {
      ...model.get(),
      minimumSpend: entityMinimumSpend,
      costPerMbit: this.getCostPerMbit({ traffic, ingress, egress, cost }),
      trafficCost: this.getCostPerMbit({ traffic, ingress, egress, cost: trafficCost }),

      edge: {
        ...edge,
        costPerMbit: this.getCostPerMbit(edge),
        trafficCost: this.getCostPerMbit({
          traffic: edge.traffic,
          ingress: edge.ingress,
          egress: edge.egress,
          cost: edge.trafficCost
        })
      },
      backbone: {
        ...backbone,
        costPerMbit: this.getCostPerMbit(backbone),
        trafficCost: this.getCostPerMbit({
          traffic: backbone.traffic,
          ingress: backbone.ingress,
          egress: backbone.egress,
          cost: backbone.trafficCost
        })
      },

      previous: {
        ...previousModel,
        costPerMbit: this.getCostPerMbit(previousModel),
        trafficCost: this.getCostPerMbit({
          ingress: previousModel.ingress,
          egress: previousModel.egress,
          cost: previousModel.trafficCost
        })
      }
    };
  };

  fetchLatestSnapshotByType = (type, id) => api.get(`/api/ui/cost/latestSnapshotByType/${type}/${id}`);

  exportCostGroupSnmpData = (cost_group_id, date) => {
    const path = `/api/ui/cost/snmp/${cost_group_id}/${date}`;
    const type = 'csv';
    const options = { path, type };
    this.store.$exports.addLoadingExport(options);
    return api.get(path, { rawResponse: true }).then((response) => {
      this.store.$exports.clearLoadingExport(options);
      const fileName =
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(response.headers['content-disposition'])?.[1] || 'export.csv';
      this.store.$exports.addPayload(response.text, { ...options, fileName });
    });
  };
}

export default new CostStore();
