import { computed } from 'mobx';
import moment from 'moment';

import api from 'core/util/api';

import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { addFilters } from 'app/stores/query/FilterUtils';
import { transformMitigationPredicateToFilter } from './mitigationsUtils';
import {
  PLATFORM_TYPES,
  STATUS_TO_STATES,
  mitigationDetailsToFilterKeysMap,
  mitigationMethodFieldToMitigationDetailsMap
} from './mitigationsConstants';

import ActiveMitigationCollection from './ActiveMitigationCollection';
import ManualMitigationModel from './ManualMitigationModel';
import MitigationPlatformCollection from './MitigationPlatformCollection';
import MitigationMethodCollection from './MitigationMethodCollection';

export class MitigationsStore {
  activeMitigationCollection = new ActiveMitigationCollection(); // active and potentially historical too

  // All platforms and all associated methods
  platformsCollection = new MitigationPlatformCollection();

  methodsCollection = new MitigationMethodCollection();

  mitigationActions = {};

  getPlatformType = (value) => PLATFORM_TYPES.find((type) => type.value === value);

  getMethodsById = (methodIds) => this.methodsCollection.models.filter((method) => methodIds.includes(method.id));

  fetchMitigation(id) {
    return api.get(`/api/ui/mitigations/${id}`).then((mitigationResponse) => {
      if (mitigationResponse && mitigationResponse.length === 1) {
        return mitigationResponse[0];
      }

      return undefined;
    });
  }

  fetchMit2bgpPreview(body) {
    return api.post('/api/ui/mitigations/mit2bgp', { body });
  }

  get mitigationMethodFieldToPolicyDimensionMap() {
    return {
      destIpCidr: 'IP_dst',
      sourceIpCidr: 'IP_src',
      protocols: 'Proto',
      srcPorts: 'Port_src',
      dstPorts: 'Port_dst'
    };
  }

  getFlowspecPreviewQuery({ alertModel, flowspecDetails, context }) {
    // build the base query based on the insight (alarm) - this will get us the baseline for the query including inferred dimension values
    // const query = this.store.$insights.getInsightQuery(insight);
    const query = this.store.$alerting.getAlertQuery(alertModel);

    // clear the end time so that we're looking at current traffic up until this point
    query.ending_time = moment.utc().format(DEFAULT_DATETIME_FORMAT);
    // clear filters so we base them on the context we're in (alarm or mitigation)
    query.filters = null;

    // look through flowspec details, check what we infer and what we have configured values for in order to build the filters
    // if we have a mitigation context, we work with target object on the mitigation
    // if we are in an alert context, we use the alert object dimension to key part
    const targetObj = context.get ? context.get('targetObj') : context.targetObj;

    Object.keys(flowspecDetails).forEach((flowspecKey) => {
      const value = flowspecDetails[flowspecKey];

      if (value && value.infer) {
        const filterField = mitigationDetailsToFilterKeysMap[flowspecKey];
        const policyDimension = this.mitigationMethodFieldToPolicyDimensionMap[flowspecKey];
        const filterValue = alertModel
          ? alertModel.get('key.value')?.[policyDimension] || alertModel.dimensionToKeyPart?.[policyDimension]
          : targetObj[mitigationMethodFieldToMitigationDetailsMap[flowspecKey]];

        if (filterField && filterValue !== undefined) {
          addFilters(query, [
            {
              filterField,
              filterValue,
              operator: 'ILIKE'
            }
          ]);
        }
      } else if (value && value.value && !value.infer) {
        const nestedFilterGroup = {
          connector: 'Any',
          not: false,
          name: '',
          named: false,
          filterGroups: [],
          autoAdded: '',
          saved_filters: []
        };

        const { predicates, groups } = value.value;

        nestedFilterGroup.filters = predicates
          .map((predicate) =>
            transformMitigationPredicateToFilter(predicate, mitigationDetailsToFilterKeysMap, flowspecKey)
          )
          .filter(Boolean);

        if (groups && groups.length) {
          groups.forEach((group) => {
            const groupFilterGroup = {
              connector: 'All',
              not: false,
              filters: group.predicates
                .map((groupPredicate) =>
                  transformMitigationPredicateToFilter(groupPredicate, mitigationDetailsToFilterKeysMap, flowspecKey)
                )
                .filter(Boolean),
              name: '',
              named: false,
              filterGroups: [],
              autoAdded: '',
              saved_filters: []
            };

            nestedFilterGroup.filterGroups.push(groupFilterGroup);
          });
        }

        if (!query.filters) {
          query.filters = {
            connector: 'All',
            filters: [],
            filterGroups: []
          };
        }

        if (nestedFilterGroup.filterGroups.length > 0 || nestedFilterGroup.filters.length > 0) {
          query.filters.filterGroups.push(nestedFilterGroup);
        }
      }
    });

    return query;
  }

  async loadMitigationActions() {
    return api
      .get('/api/ui/mitigations/actions', { showErrorToast: false })
      .then((actions) => {
        if (actions && actions.stateToActions) {
          this.mitigationActions = actions.stateToActions;
        } else {
          this.mitigationActions = {};
        }
      })
      .catch(() => {
        // we have error logs in node for this particular problem so we'll investigate there
        // we do not want to expose to users internal api url path variables and so on.
        // at worst, they won't be able to use actions on their mitigations, but realistically, they won't get here
        // if they have properly configured mitigation platforms and methods OR they shouldn't run actions if the former isn't true
      });
  }

  async loadActiveMitigations(filters = {}) {
    return this.loadMitigationActions().then(() => {
      const serverFilters = {
        lookback: 86400,
        states: ['Waiting', 'Failed', 'Active'].flatMap((status) => STATUS_TO_STATES[status]),
        ...filters
      };

      return this.activeMitigationCollection.setServerFilter(serverFilters, { force: true });
    });
  }

  get newManualMitigationModel() {
    return new ManualMitigationModel({ $mitigations: this });
  }

  @computed
  get unassignedMitigationMethods() {
    const assignedMethodIds = this.platformsCollection?.platformMethods.map((platformMethod) => platformMethod.value);
    const unassigned = this.methodsCollection.unfiltered.filter(
      (method) => !assignedMethodIds.includes(method.id) && method.type
    );
    return unassigned;
  }

  @computed
  get manualMitigationOptions() {
    return (this.platformsCollection.platformMethods || [])
      .map((option) => ({
        ...option,
        value: `${option.platform.id}-${option.method.id}`,
        label: `${option.platform.get('name')} - ${option.method.get('name')}`
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  getMitigationStatusColor(status) {
    const colorHash = {
      Inactive: 'muted',
      Active: 'success',
      Failed: 'danger',
      Waiting: 'warning'
    };
    return colorHash[status] || 'muted';
  }

  async mitigate({ actionConfig, alarm_state, mitigation_id }) {
    return api.post('/api/ui/mitigations/mitigate', { data: { action: actionConfig, alarm_state, mitigation_id } });
  }

  isDeviceInRTBHPlatform(device) {
    const platform = this.platformsCollection.models.find(
      (model) => model.type === 'RTBH' && model.devices.includes(`${device.id}`)
    );

    return !!platform;
  }

  getMethodOptionsByType(platformType) {
    const options = [];
    this.methodsCollection.models.forEach((model) => {
      const { name, id, type } = model.get();
      if (platformType === type) {
        options.push({
          label: name,
          value: id
        });
      }
    });
    return options;
  }

  getAssociatedMitigationPlatforms = (deviceId, allowedTypes) =>
    this.platformsCollection.models.filter((platform) => {
      if (allowedTypes && allowedTypes.length > 0) {
        if (!allowedTypes.includes(platform.get('type'))) {
          return false;
        }
      }

      return Array.isArray(platform.devices) && platform.devices.includes(`${deviceId}`);
    });

  getMitigationPlatformFromMethod = (methodId) =>
    this.platformsCollection.platformMethods.find((option) => option.value === `${methodId}`);

  getActiveMitigationsUsingPlatform(platformId) {
    return this.activeMitigationCollection.filter((model) => model.get('platformID') === platformId);
  }

  // Optional platformId param to scope results to a stricter method/platform match
  getActiveMitigationsUsingMethod(methodId, platformId) {
    return this.activeMitigationCollection.filter((model) => {
      const matchesMethod = model.get('methodID') === methodId;
      const matchesPlatform = platformId ? model.get('platformID') === platformId : true;

      return matchesMethod && matchesPlatform;
    });
  }

  async getActiveMitigationsUsingDevice(deviceID) {
    const associatedPlatformIds = this.getAssociatedMitigationPlatforms(deviceID, ['mit2bgp', 'flowspec']).map(
      (platform) => platform.id
    );

    return this.activeMitigationCollection.filter((model) => associatedPlatformIds.includes(model.get('platformID')));
  }

  getPoliciesUsingPlatform(platformId) {
    return this.store.$alerting.policyCollection.unfiltered.filter((model) =>
      model.mitigations.some((mitigation) => mitigation.platformID === platformId)
    );
  }

  getPoliciesUsingMethod(methodId, platformId) {
    return this.store.$alerting.policyCollection.unfiltered.filter((model) => {
      if (platformId) {
        return model.mitigations.some(
          (mitigation) => mitigation.methodID === methodId && mitigation.platformID === platformId
        );
      }

      return model.mitigations.some((mitigation) => mitigation.methodID === methodId);
    });
  }

  @computed
  get allUsedPlatformsAndMethods() {
    if (!this.allMitigationsCollection) {
      this.allMitigationsCollection = new ActiveMitigationCollection();
      this.allMitigationsCollection.serverFilter = {
        states: [],
        lookback: 24 * 90 * 3600
      };
    }

    return this.allMitigationsCollection.queuedFetch().then(() => this.allMitigationsCollection.platformMethodOptions);
  }
}

export default new MitigationsStore();
