import { action, computed, observable } from 'mobx';
import { get, invert, isInteger, sortBy, toNumber } from 'lodash';
import api from 'core/util/api';
import { findCategoryOptions, flatten } from 'app/util/dictionaryUtils';
import { EVENT_POLICY_TYPES } from '@kentik/ui-shared/alerting/constants';
import {
  ALERT_DIMENSIONS_WHITELIST,
  ALERT_SYSLOG_DIMENSIONS_WHITELIST,
  ALERT_SNMP_TRAP_DIMENSIONS_WHITELIST,
  ALERT_METRIC_OPTIONS,
  ALERT_METRIC_SPECIAL_DEVICE_LABELS
} from 'app/util/constants';

function getGroupDimension(tagLabels, category, metrics, groupName) {
  return Object.keys(metrics).map((metric) => ({
    value: metric,
    label: metrics[metric],
    category,
    group: groupName || category,
    tagLabel: tagLabels[metric] || metrics[metric]
  }));
}

function getGroupDimensions(tagLabels, category, optionGroups) {
  const [src, dst, other, subGroups] = optionGroups;

  const result = [
    getGroupDimension(tagLabels, 'Source', src),
    getGroupDimension(tagLabels, 'Destination', dst),
    getGroupDimension(tagLabels, '', other)
  ];

  if (subGroups) {
    const subGroupOptions = {};

    Object.keys(subGroups).forEach((subGroupName) => {
      subGroupOptions[subGroupName] = getGroupDimensions(tagLabels, category, subGroups[subGroupName]);
    });

    result.push(subGroupOptions);
  }

  return result;
}

function getGroupedDimensionOptions(categorizedOptionData, tagLabels = {}) {
  const dimensionOptions = {};

  Object.keys(categorizedOptionData).forEach((category) => {
    const options = categorizedOptionData[category];

    if (Array.isArray(options)) {
      dimensionOptions[category] = getGroupDimensions(tagLabels, category, options);
    } else {
      dimensionOptions[category] = getGroupDimension(tagLabels, category, options);
    }
  });

  return dimensionOptions;
}

function getGroupFilters(tagLabels, category, optionGroups) {
  const [src, dst, src_dst, other, subGroups] = optionGroups;

  const result = [
    getGroupDimension(tagLabels, 'Source', src),
    getGroupDimension(tagLabels, 'Destination', dst),
    getGroupDimension(tagLabels, 'Source or Dest', src_dst),
    getGroupDimension(tagLabels, '', other)
  ];

  if (subGroups) {
    const subGroupOptions = {};

    Object.keys(subGroups).forEach((subGroupName) => {
      subGroupOptions[subGroupName] = getGroupFilters(tagLabels, category, subGroups[subGroupName]);
    });

    result.push(subGroupOptions);
  }

  return result;
}

function getGroupedFilterOptions(categorizedOptionData, tagLabels = {}) {
  const dimensionOptions = {};

  Object.keys(categorizedOptionData).forEach((category) => {
    const options = categorizedOptionData[category];

    if (Array.isArray(options)) {
      dimensionOptions[category] = getGroupFilters(tagLabels, category, options);
    } else {
      dimensionOptions[category] = getGroupDimension(tagLabels, category, options);
    }
  });

  return dimensionOptions;
}

function getFlatDimensionOptions(categorizedOptionData, tagLabels = {}) {
  return flatten(getGroupedDimensionOptions(categorizedOptionData, tagLabels));
}

function getFlatFilterOptions(categorizedOptionData, tagLabels = {}) {
  return flatten(getGroupedFilterOptions(categorizedOptionData, tagLabels));
}

// this helper produces an array of select options for rightFilterField selectors, keyed by filterField
function getFilterFamilyOptions({
  filterTypeInverses,
  filterTypeInverseGroups,
  flatParametricFilterTypes,
  flatFilterFieldOptions
}) {
  const options = {};
  const labels = flatFilterFieldOptions.reduce((agg, option) => {
    agg[option.value] = `${option.group}${option.group && ' '}${option.label}`;
    return agg;
  }, {});

  // first, take 1:1 inverses and start building options
  Object.entries(filterTypeInverses).forEach(([field, inverse]) => {
    options[field] = [{ value: inverse, label: labels[inverse] }];
  });

  // now, take filter field families and expand options
  // exclude custom dimension family because it doesn't make a lot of sense unless it's from inverses
  const { custom_dimension, ...rest } = flatParametricFilterTypes;
  const families = filterTypeInverseGroups.concat(Object.values(rest));
  families.forEach((family) => {
    // safety check for solo parametric families, or empty arrays
    if (family.length <= 1) {
      return;
    }

    // first convert the family to options
    const familyOptions = family.reduce((agg, member) => {
      // don't support bi-di fields
      if (!member.includes('|')) {
        agg.push({ value: member, label: labels[member] });
      }
      return agg;
    }, []);

    // update rightFilterField options for each family member, excluding itself and prior inclusions
    familyOptions.forEach((option) => {
      const { value } = option;
      options[value] = options[value] || [];
      options[value].push(
        ...familyOptions.filter((opt) => opt.value !== value && !options[value].find((o) => o.value === opt.value))
      );
    });
  });

  return options;
}

export class Dictionary {
  @observable
  loadingDictionary;

  dictionary;

  filterFieldValueOptions = {};

  dimensionOptions = {};

  metricOptions = {};

  @action
  initialize() {
    this.loadingDictionary = true;

    return api.get('/api/ui/dictionary').then((response) => {
      this.dictionary = response || {};
      this.loadingDictionary = false;

      this.initializeOptions();
      if (!this.store.$app.isSubtenant && !this.store.$app.isSharedLink) {
        this.initializeAlertingOptions();
      }
    });
  }

  clearDictionaryCache() {
    return api.post('/api/ui/dictionary/clear');
  }

  get(entry, defaultValue) {
    return get(this.dictionary, entry, defaultValue);
  }

  /**
   * will return { [key] : [name] } pairs from cloudMetadata dictionary
   */
  getCloudMetadataKeyToNameMap(cloud) {
    const cloudMetadata = this.get('cloudMetadata');

    if (!cloud || !cloudMetadata?.[cloud]) {
      return {};
    }

    return Object.keys(cloudMetadata[cloud].regions).reduce((carry, regionKey) => {
      carry[regionKey] = cloudMetadata[cloud].regions[regionKey].name;
      return carry;
    }, {});
  }

  initializeOptions() {
    const { chartTypes, chartTypeLabels, queryFilters } = this.dictionary;
    const {
      filterTypes,
      standardFilterOperators,
      rightFilterFieldOperators,
      parametricFilterTypes,
      operators,
      options,
      showValueInOptions,
      filterTypeInverses,
      filterTypeInverseGroups,
      flatParametricFilterTypes
    } = queryFilters;

    this.flatFilterFieldOptions = getFlatFilterOptions(filterTypes);
    this.filterFieldOptions = getGroupedFilterOptions(filterTypes);
    this.rightFilterFieldOptions = getFilterFamilyOptions({
      filterTypeInverses,
      filterTypeInverseGroups,
      flatParametricFilterTypes,
      flatFilterFieldOptions: this.flatFilterFieldOptions
    });

    this.flatDimensionOptions = getFlatDimensionOptions(chartTypes, chartTypeLabels);
    this.dimensionOptions = getGroupedDimensionOptions(chartTypes, chartTypeLabels);

    this.flatCustomDimensionOption = this.flatFilterFieldOptions.filter(
      (opt) => opt.value.startsWith('c_') || opt.value.startsWith('kt_')
    );

    this.standardFilterOperators = Object.keys(standardFilterOperators).map((operator) => ({
      value: operator,
      label: standardFilterOperators[operator]
    }));

    this.parametricDashboardFilterOptions = sortBy(parametricFilterTypes, 'label');

    this.filterOperatorOptions = Object.keys(operators).reduce((opts, field) => {
      opts[field] = Object.keys(operators[field]).map((value) => ({
        label: operators[field][value],
        value
      }));
      return opts;
    }, {});

    const rightFilterFieldOperatorOptions = Object.keys(rightFilterFieldOperators).map((operator) => ({
      value: operator,
      label: rightFilterFieldOperators[operator]
    }));

    Object.keys(this.rightFilterFieldOptions).forEach((field) => {
      this.filterOperatorOptions[field] = (this.filterOperatorOptions[field] || this.standardFilterOperators).concat(
        rightFilterFieldOperatorOptions
      );
    });

    this.addFilterFieldValueOptions(
      Object.keys(options).reduce((opts, field) => {
        const addValueToLabel = showValueInOptions.includes(field);
        opts[field] = Object.keys(options[field]).map((value) => ({
          label: `${options[field][value]}${addValueToLabel ? ` (${value})` : ''}`,
          value
        }));
        return opts;
      }, {})
    );

    this.flatFilterLabels = this.flatFilterFieldOptions.map((option) => ({
      value: option.value,
      label: `${option.group} ${option.label}`.trim(),
      group: option.group
    }));
    this.flatDimensionLabels = this.flatDimensionOptions.map((option) => ({
      value: option.value,
      label: `${option.group} ${option.label}`.trim(),
      group: option.group
    }));
    this.dimensionLabelsMap = this.flatDimensionLabels.reduce((acc, option) => {
      acc[option.value] = option;
      return acc;
    }, {});
  }

  initializeAlertingOptions() {
    return api.get('/api/ui/alerting/supported-dimensions').then((response) => {
      if (response.success) {
        response.flex_columns.forEach((col) => {
          ALERT_DIMENSIONS_WHITELIST.add(col);

          if (col.startsWith(`ktappprotocol__event_${EVENT_POLICY_TYPES.SYSLOG}`)) {
            ALERT_SYSLOG_DIMENSIONS_WHITELIST.add(col);
          }

          if (col.startsWith(`ktappprotocol__event_${EVENT_POLICY_TYPES.SNMP_TRAP}`)) {
            ALERT_SNMP_TRAP_DIMENSIONS_WHITELIST.add(col);
          }
        });
        response.flex_metrics.forEach((metric) => {
          if (!ALERT_METRIC_OPTIONS.find((alertMetric) => alertMetric.value === metric.value)) {
            // Prepend special device labels
            if (metric.value.startsWith('ktsubtype__')) {
              const [, deviceType] = metric.value.split('__');
              const labelMatch = ALERT_METRIC_SPECIAL_DEVICE_LABELS[deviceType];
              metric.label = labelMatch ? `${labelMatch}: ${metric.label}` : metric.label;
            }
            ALERT_METRIC_OPTIONS.push({ ...metric, unit: metric.unit || metric.value });
          }
        });
      } else {
        console.error('Alerting API error', response.error);
      }
    });
  }

  initializeDeviceTypes() {
    if (this.dictionary.deviceTypes) {
      return Promise.resolve();
    }
    return api.get('/api/ui/sudo/device-types').then((deviceTypes) => {
      this.dictionary.deviceTypes = deviceTypes;
    });
  }

  addFilterFieldValueOptions = (optionMap) => {
    Object.keys(optionMap).forEach((field) => {
      this.filterFieldValueOptions[field] = optionMap[field];
    });
  };

  getSelectOptions = (section, options = {}) => {
    const { parseKeys = false, omitValues = [], sortByField = null } = options;
    const selectOptionMap = get(this.dictionary, section) || {};

    const selectOptions = [];

    Object.keys(selectOptionMap).forEach((optionKey) => {
      const value = parseKeys ? parseInt(optionKey, 10) : optionKey;

      if (!omitValues.includes(value)) {
        selectOptions.push({ value, label: selectOptionMap[optionKey] });
      }
    });

    if (sortByField) {
      return sortBy(selectOptions, sortByField);
    }

    return selectOptions;
  };

  getAvailableFiltersForParametricType = (parametric_type) => {
    if (!parametric_type) {
      return [];
    }

    const { flatParametricFilterTypes } = this.dictionary.queryFilters;
    const parametricFilterOptions = flatParametricFilterTypes[parametric_type.type];

    if (!parametricFilterOptions) {
      return [];
    }

    return this.flatFilterLabels.filter((filter) => parametricFilterOptions.includes(filter.value));
  };

  getHelpUrl = (section, key) => {
    const { helpUrls } = this.dictionary;
    return `${helpUrls.helpBaseUrl}/${helpUrls[section][key]}`;
  };

  getFlatDimensionOptionsForCategory = (category) => {
    const groupedCategoryOptions = findCategoryOptions(this.dimensionOptions, category) || [];
    return groupedCategoryOptions.flatMap((arr) => arr);
  };

  getFilterValueValidator = (dimension) => {
    const { options, validators, validatorsBase, defaultValidator, neverExactMatch } = this.dictionary.queryFilters;
    const dimensionValidator = validators[dimension];

    if (options[dimension] && !neverExactMatch.includes(dimension)) {
      return ['required', { in: Object.keys(options[dimension]) }];
    }
    if (!dimensionValidator) {
      // no rules specified, use default validator
      return [`regex:/${validatorsBase[defaultValidator.replace('base:', '')]}/`];
    }
    if (dimensionValidator.startsWith('base:')) {
      const rules = [];
      if (dimensionValidator.endsWith('+')) {
        // + rules require one or more values, so add required rule
        rules.push('required');
      }
      // rules specified as a regex from backend, use it.
      rules.push(`regex:/${validatorsBase[dimensionValidator.replace('base:', '')]}/`);
      return rules;
    }

    // rules specified, but not a hard coded regex from backend, so assume local rules.
    return dimensionValidator.split(',');
  };

  @computed({ keepAlive: true })
  get columnToUnitsInverse() {
    return invert(this.dictionary.columnToUnits);
  }

  /**
   * Most often used in $insights and $alerting to translate api dimensions, filters, metrics etc into something that can be used by $query
   * @param {string} dimension
   * @param {string} [value] optional value
   * @returns {string}
   */
  getMetricColumn = (dimension, value) => {
    // metricColumns will normally translate 'Proto' to 'i_protocol_name' which is fine if we are trying to make a 'i_protocol_name === "UDP"' filter.
    // However, the alertAPI might send us 'Proto' and an integer value. In this case, we need 'protocol' so that we end up with 'protocol === 17'
    if (dimension === 'Proto' && isInteger(toNumber(value))) {
      return 'protocol';
    }

    if (dimension === 'Geography_dst') {
      return 'dst_geo';
    }

    if (dimension === 'Geography_src') {
      return 'src_geo';
    }

    if (dimension === 'dst_route_prefix_len') {
      return 'inet_dst_route_prefix';
    }

    if (dimension === 'src_route_prefix_len') {
      return 'inet_src_route_prefix';
    }

    if (dimension === 'i_device_id' && isInteger(toNumber(value))) {
      return 'i_device_id';
    }

    return this.dictionary.metricColumns[dimension] || dimension;
  };
}

export default new Dictionary();
